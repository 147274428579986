@charset "utf-8";

@import url('../fonts/fonts.css');

* {
    margin: 0;
    padding: 0;
    outline: none;
}
html, body, div, span, h1, h2, h3, h4, h5, h6, p, em, img, strong, sub, sup, b, u, i,  dl, dt, dd, ol, ul, li, fieldset, form, label, table, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    background: transparent;
    font-size: 100%;
}
header, nav, section, article, aside, footer {
    display: block;
}
h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
}

html {  
    height:100%; 
}
body {  
    position:relative; 
    height:100%; 
    font-family: $font; 
    color: $color-body;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.3;
    background: $bg-body;
    @media (max-width: $md-desktop) {
        font-size: 18px;
    }
    @media (max-width: $md-mobile-lg) {
        font-size: 16px;
    }
}
body *,
body *:before,
body *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; 
}

img { 
    border: none;
    max-width: 100%;
    vertical-align: bottom;
}

ul {
  list-style: none;
}

a { 
    color: $color-body;
    text-decoration: none; 
    margin: 0; padding: 0;
}
