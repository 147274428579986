
/**************************************************************
footer
**************************************************************/
.footer {
	padding-bottom: 25px;
    &:first-child {
        padding: 200px 0;
    }
    &__inner {
        border-radius: 30px;
        border: 2px solid $color-gray-light;
        padding: 46px 57px 57px;
    }
    &__top {
        display: flex;
        justify-content: space-between;
        gap: 40px;
    }
    &__menu {
        width: 100%;
        max-width: 235px;
        ul {
            display: grid;
            grid-template-columns: auto auto;
            grid-gap: 3px 15px;
        }
        li {
            &:nth-child(2n) {
                text-align: right;
            }
            a {
                font-size: 20px;
                text-transform: uppercase;
                transition: all 0.3s ease-in-out;
                &:hover {
                    color: $color-body-hover;
                }
            }
        }
    }
    &__contacts {
        text-align: right;
        &-item {
            font-family: $font-oddval;
            font-size: 75px;
            font-weight: 600;
            line-height: 0.9;
            letter-spacing: -0.045em;
            text-transform: uppercase;
            display: inline-block;
            & + & {
                margin-top: 20px;
            }
        }
        &-addr {
            margin-top: 20px;
            text-transform: uppercase;
            font-size: 20px;
            color: $color-gray;
        }
    }


    &__bottom {
        margin-top: 90px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 40px;
        font-size: 15px;
    }
    &__inf {
        
    }
    &__copy {
        text-transform: uppercase;
    }
    &__link {
        text-transform: uppercase;
        color: $color-gray;
        transition: all 0.3s ease-in-out;
        display: inline-block;
        margin-top: 13px;
        &:hover {
            color: $color-body;
        }
    }
    &__social {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    &__develop {
        margin-top: 20px;
        a {
            letter-spacing: 0.32em;
            font-weight: 700;
            text-transform: uppercase;
            &:hover {
                color: $color-body-hover;
            }
        }
    }

    @media (max-width: $md-desktop-md) {
        &__contacts {
            &-item {
                font-size: 65px;
            }
        }
    }
    @media (max-width: $md-desktop) {
        &__menu {
            max-width: 220px;
            li a {
                font-size: 18px;
            }
        }
        &__contacts {
            &-item {
                font-size: 50px;
            }
            &-addr {
                font-size: 18px;
            }
        }
        &__bottom {
            font-size: 14px;
        }
    }
    @media (max-width: $md-tablet) {
        &__inner {
            border-radius: 20px;
            padding: 40px 30px;
        }
        &__top {
            flex-direction: column-reverse;
        }
        &__contacts {
            text-align: left;
            &-item {
                font-size: 40px;
                display: block;
                & + & {
                    margin-top: 10px;
                }
            }
        }
        &__bottom {
            gap: 0;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 40px;
        }
        &__social {
            order: -1;
            align-items: flex-start;
        }
        &__inf {
            margin-top: 50px;
        }
        &__develo {
            margin-top: 36px;
        }
    }
    @media (max-width: $md-tablet-sm) {
        
    }
    @media (max-width: $md-mobile-lg) {
        &__inner {
            padding: 40px 20px;
        }
        &__top {
            gap: 36px;
        }
        &__menu {
            li {
                text-align: left !important;
                a {
                    font-size: 16px;
                }
            }
        }
        &__contacts {
            &-item {
                font-size: 24px;
            }
            &-addr {
                font-size: 14px;
            }
            
        }
        &__bottom {
            font-size: 12px;
            margin-top: 36px;
        }
    }
}
