/**************************************************************
swiper
**************************************************************/
.swiper {

}
.swiper-slide {
    height: auto;
}
.swiper-pagination {
  display: flex;
  gap: 15px;
  &-bullet {
    margin: 0  !important;
    width: 15px; height: 15px;
    border: 2px solid $color-gray-light;
    background: $color-gray-light;
    transition: all 0.3s ease-in-out;
    opacity: 1;
    &-active {
     background: none !important;
    }
  }
  &--white &-bullet {
    border-color: #fff;
    background: #fff;
  }
  &--white &-bullet-active {
    
  }
  &-clickable .swiper-pagination-bullet {
    cursor: pointer;
  }
}
.swiper-button {
    width: 50px; height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    color: $color-body;
    border-radius: 100%;
    border: 2px solid $color-gray-light;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:after {
        display: none;
    }
    .icon {
      width: 32%;
      position: relative;
    }
    &-next .icon {
      transform: translateX(2px);
    }
    &-prev .icon {
      transform: translateX(-1px);
    }
    &--white {
      color: #fff;
      border-color: #fff;
    }
    &:hover {
        background: $color-body;
        border-color: $color-body;
        color: #fff;
    }
}
.swiper-scrollbar {
  background: none ;
}

.swiper-nav {
  display: flex;
  align-items: center;
  gap: 24px;
  .swiper-button {
    position: static;
    transform: initial;
  }
  .swiper-pagination {
    position: static;
    width: auto;
  }
}



