.section {
    &__mglr {
        margin: 0 $pd-container-lg;
    }
    &__head {
        margin-bottom: 72px;
        &.hcols {
            display: flex;
            justify-content: space-between;
            gap: 40px;
        }
    }
    &__title {
        font-size: 48px;
        text-transform: uppercase;
        &.fs--sm {
            
        }
    }
    &__intro {
        
        .text--center & {
            margin-left: auto;
            margin-right: auto;
        }
    }
    &__buttons {
        margin-top: 68px;
        text-align: center;
    }
    &__mg {
        margin: 140px 0;
        &--lg {
            margin: 250px 0;
        }
        &--md {
           margin: 185px 0;
        }
    }
    &__mgt {
        margin-top: 140px;
        &--lg {
            margin-top: 250px;
        }
        &--md {
           margin-top: 185px;
        }
    }
    &__mgb {
        margin-bottom: 140px;
        &--lg {
            margin-bottom: 250px;
        }
        &--md {
           margin-bottom: 185px;
        }
    }
    &__first {
        margin-top: 100px;
    }
    &__pd {
        padding: 100px 0;
    }
    &__pdt {
        padding-top: 70px;
    }
    &__tab {
        display:none;
        &.show {
            display:block;
        }
    }
    
    @media (max-width: $md-desktop-lg) {
        &__inner {
           
        }
        &__head {
         
        }
        &__title {
          
        }

        &__mg {
           
            &--md {
            
            }
            &--sm {
             
            }
        }
        &__mgt {
            &--sm {
               
            }
        }
        &__buttons {
           
        }
    }
    @media (max-width: $md-desktop) {
       
        &__head {
            margin-bottom: 60px;
        }
        &__title {
            font-size: 40px;
        }
        &__intro {
            
        }
        &__buttons {
            margin-top: 50px;
        }
        &__mg {
            margin: 125px 0;
            &--lg {
                margin: 160px 0;
            }
            &--md {
               margin: 150px 0;
            }
        }
        &__mgt {
            margin-top: 125px;
            &--lg {
                margin-top: 160px;
            }
            &--md {
               margin-top: 150px;
            }
        }
        &__mgb {
            margin-bottom: 125px;
            &--lg {
                margin-bottom: 160px;
            }
            &--md {
               margin-bottom: 150px;
            }
        }
        &__first {
            margin-top: 90px;
        }
        &__pd {
            padding: 80px 0;
        }
        &__pdt {
            padding-top: 70px;
        }
    }

    @media (max-width: $md-tablet) {
       
        &__head {
            
            &.hcols {
                flex-direction: column;
                gap: 20px;
            }
        }
    }
    @media (max-width: $md-tablet-sm) {
        &__head {
            margin-bottom: 50px;
        }
        &__title {
            font-size: 28px;
            br {
                display: none;
            }
        }
        &__intro {
            
        }
        &__buttons {
            margin-top: 34px;
            .btn {
                width: 100%;
                max-width: 400px;
            }
        }
        &__mg {
            margin: 110px 0;
            &--lg {
                margin: 110px 0;
            }
            &--md {
               margin: 110px 0;
            }
        }
        &__mgt {
            margin-top: 110px;
            &--lg {
                margin-top: 110px;
            }
            &--md {
               margin-top: 110px;
            }
        }
        &__mgb {
            margin-bottom: 110px;
            &--lg {
                margin-bottom: 110px;
            }
            &--md {
               margin-bottom: 110px;
            }
        }
        &__first {
            margin-top: 40px;
        }
        &__pd {
            padding: 60px 0;
        }
        &__pdt {
            padding-top: 70px;
        }
    }
    @media (max-width: $md-mobile) {
    }
}

.title {
    
}
.text {
   
}

.text--center {
    text-align: center;
}
.text--white {
    color: #ffff;
}
.text--under {
    text-decoration: underline;
        text-underline-offset: 4px;
        text-decoration-thickness: 1px;
}