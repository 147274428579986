
.content__text {
    > * {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
        &:first-child {
            margin-top: 0;
        }
    }
    a {
        
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    h1, h2, h3, h4, h5 {
        text-transform: uppercase;
        line-height: 1.1;
    }
    h1 {
        font-size: 48px;
        margin: 45px 0;
    }
    h2 {
        font-size: 45px;
        margin: 25px 0;
    }
    h3 {
        font-size: 36px;
        margin: 20px 0;
    }
    h4 {
        font-size: 30px;
        margin: 15px 0;
    }
    h5 {
        font-size: 22px;
        margin: 10px 0;
    }
    ul,
    ol {
      padding-left: 26px;
    }
    ol {
        list-style-type: none; 
        counter-reset: num;
    }
    li {
        position: relative;
        & + li {
            margin-top: 15px;
        }
    }
    ul li:before {
      position: absolute;
      top: 10px;
      right: 100%;
      margin-right: 11px; 
      width: 6px; height: 6px;
      border-radius: 100%;
      background: $color-body;
      content: '';
    }
    ol li:before {
      content: counter(num) '.'; 
      counter-increment: num;
      display: inline-block;	
      position: absolute;
      top: 0px;
      right: 100%;
      margin-right: 8px; 
      text-align: right;
    }
    img {
        margin: 80px 0;
    }
    @media (max-width: $md-desktop) {
        h1 {
            font-size: 40px;
        }
        h2 {
            font-size: 36px;
        }
        h3 {
            font-size: 30px;
        }
        h4 {
            font-size: 24px;
        }
        h5 {
            font-size: 20px;
        }
        
    }
    @media (max-width: $md-tablet-sm) {
        > * {
            margin-bottom: 15px;
        }
        h1 {
            font-size: 28px;
        }
        h2 {
            font-size: 24px;
        }
        h3 {
            font-size: 20px;
        }
        h4 {
            font-size: 18px;
        }
        h5 {
            font-size: 16px;
        }
        ul {
            padding-left: 17px;
            li:before {
                width: 4px; height: 4px;
                top: 7px;
            }
        }
        img {
            margin: 40px 0;
        }

    }
}
