$font: 'Jost', sans-serif;
$font-oddval: 'Oddval', sans-serif;

$bg-body: #FFF;
$bg-gray-light: #F8FAFC;

$color-body: #393939;
$color-body-hover: #000;
$color-gray: #868686;
$color-gray-light: #E5E5E5;



$width-container: 1200px;
$pd-container: 16px;
$pd-container-lg: 20px;
$pd-container-smx: 15px;

$md-desktop-lg: 1699.98px;
$md-desktop-md: 1439.98px;
$md-desktop: 1219.98px;
$md-tablet: 1023.98px;
$md-tablet-sm: 767.98px;
$md-mobile-lg: 609.98px;
$md-mobile: 478.98px;
$md-mobile-sm: 399.98px;
$md-mobile-smx: 360px;

$header-h: 90px;
$header-h-t: 80px;
$header-h-m: 60px;