/**************************************************************
forms
**************************************************************/
@include input-placeholder('.input', $color-gray, 1);

.input:focus::-webkit-input-placeholder {color: transparent; opacity: 0;}
.input:focus::-o-input-placeholder {  color: transparent; opacity: 0;}
.input:focus::-moz-input-placeholder {  color: transparent; opacity: 0;}
.input:focus:-moz-input-placeholder { color: transparent; opacity: 0;}
.input:focus::-ms-input-placeholder { color: transparent; opacity: 0;}
.input:focus:input-placeholder {color: transparent; opacity: 0;}

input[placeholder]          {text-overflow:ellipsis;}
input::-moz-placeholder     {text-overflow:ellipsis;} 
input:-moz-placeholder      {text-overflow:ellipsis;} 
input:-ms-input-placeholder {text-overflow:ellipsis;}


input, button { -webkit-appearance: none;}




.input {
    width: 100%;
    height: 50px;
    padding: 0 14px;
    font-family: $font;
    font-size: 20px; 
    color: $color-body;
    text-transform: uppercase;
    background: none;
    border: 1px solid $color-gray-light;
    border-radius: 10px;
    outline: none;
    &--line {
        border: none;
        border-bottom: 1px solid #C4C4C4;
        border-radius: 0;
        height: 50px;
        padding: 0 0 10px;
    }
    &--white {
        border-color: #FFF;
        color: #FFF;
    }
    @include input-placeholder('&--white', rgba(255,255,255,0.9), 1);
    &.error {
        // background: #ffeded !important;
        border-color: #b40202 !important;
        color: #b40202;
    }
    @include input-placeholder('&.error', #b40202);
}
textarea.input {
    line-height: normal;
    padding: 10px 15px;
    resize: none;
    height: 100px;
}


.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 250px;
    height: 50px;
    padding: 0 25px;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    font-family: $font;
    text-align: center;
    text-transform: uppercase;
    background: $color-body;
    border-radius: 100px;
    border: 2px solid $color-body;
    cursor: pointer;
    outline: none;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease-in-out;
    @media (any-hover: hover) {
        &:hover {
            background: #fff;
            color: $color-body;
        }
    }
    img, svg {
        margin-left: 10px;
    }
    &--white {
        background: #fff;
        border-color: #fff;
        color: $color-body;
        @media (any-hover: hover) {
            &:hover {
                background: $color-body;
                border-color: $color-body;
                color: #fff;
            }
        }
    }
    &--bd {
        background: none;
        border: 2px solid $color-body;
        color: $color-body;
        @media (any-hover: hover) {
            &:hover {
                background: $color-body;
                color: #fff;
            }
        }
    }
    &--wall {
        width: 100%;
    }

    @media (max-width: $md-mobile-lg) {
            
        &--m-wall {
            width: 100%;
            max-width: 400px;
        }
    }
}


.form {
    &__input {
        margin-bottom: 45px;
    }
    &__submit {
        margin-top: 70px;
        .btn {
            min-width: 290px;
        }
    }
    &__connection {
        margin: 10px 0px 15px;
        &-title {
            text-align: center;
            margin-bottom: 12px;
            color: #666666;
            font-size: 18px;
            letter-spacing: 0.03em;
        }
        &-items {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0 -15px;
        }
        &-item {
            margin: 5px 15px 10px;
            font-weight: bold;
            font-size: 14px;
            letter-spacing: 0.05em;
            color: #525252;
            cursor: pointer;
            position: relative;
            border: none;
            display: flex;
            align-items: center;
        }
    }
    &__policy {
        margin-top: 13px;
        font-size: 12px;
        color: $color-gray;
        &.policy--white {
            color: $color-gray-light;
        }
        .form__input + & {
            margin-top: -30px;
        }
        a {
            color: inherit;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
    &__rchecks {
        display: flex;
        gap: 35px;
        margin-bottom: 64px;
    }
    &__rch {
        display: flex;
        flex: 1 0 0;
        cursor: pointer;
        input {
            position: absolute;
            opacity: 0; visibility: hidden;
            z-index: -1;
        }
        &-wrap {
            width: 100%;
            border-radius: 30px;
            border: 2px solid $color-gray-light;
            height: 235px;
            padding: 26px 30px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 30px;
            font-size: 25px;
            text-transform: uppercase;
            transition: all 0.15s ease-in-out;
            &:hover {
                border-color: $color-body;
            }
        }
        :checked + &-wrap {
            color: #fff;
            background: $color-body;
            border-color: $color-body;
        }
        &-icon {
            height: 35px;
            position: relative;
            img {
                position: absolute;
                left: 0; top: 0;
                height: 100%;
                transition: all 0.15s ease-in-out;
            }
            .img-active {
                opacity: 0;
            }
        }
        :checked + &-wrap &-icon {
            img {
                opacity: 0;
            }
            .img-active {
                opacity: 1;
            }
        }
    }

    @media (max-width: $md-desktop-lg) {
        &__rchecks {
            margin-bottom: 50px;
            gap: 20px;
        }
        &__rch {
            &-wrap {
                height: 200px;
                font-size: 20px;
            }
        }
    }
    @media (max-width: $md-desktop) {
        &__input {
            margin-bottom: 30px;
        }
        &__submit {
            margin-top: 40px;
        }
        &__policy {
            .form__input + & {
                margin-top: -15px;
            }
        }
    }
    @media (max-width: $md-tablet) {
        &__rch-wrap {
            border-radius: 20px;
        }
    }
    @media (max-width: $md-mobile-lg) {
        &__rchecks {
            flex-direction: column;
        }
        &__rch {
            &-wrap {
                padding: 26px 20px;
                border-radius: 10px;
            }
            &-icon {
                height: 28px;
            }
        }
        &__submit {
            text-align: center;
            .btn {
                width: 100%;
                max-width: 400px;
                min-width: 200px;
            }
        }
    }
}