@import "inc/core";

@import "fonts";
@import "reset";
@import "common";
@import "libs";
@import "forms";
@import "popup";
@import "header";
@import "footer";



/**************************************************************

**************************************************************/
.hero {
    margin-bottom: 125px;
    & + * {
        margin-top: 0;
    }
    &__inner {
        margin: 0 $pd-container-lg;
    }
    &__slider {
        position: relative;
        padding: 0 $pd-container-lg;
        margin: 0 -1*$pd-container-lg;
        &-nav {
            position: absolute;
            left: 0; bottom: 50px;
            width: 100%;
            z-index: 5;
        }
        .swiper-nav {
            
        }
        .swiper-pagination {
            margin-right: auto;
            width: auto;
        }
    }
    &__imgbg {
        @include abs-all;
        &::before {
            @include abs-all;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.55) 60.96%, rgba(0, 0, 0, 0.35) 79.64%);
            z-index: 1;
            content: '';
        }
        img {
            @include photo-cover;
            animation: zoomOutBG 20s linear forwards;
            .hero__slider & {
                animation: initial;
            }
            .swiper-slide-active & {
                animation: zoomOutBG 20s linear forwards;
            }
        }
    }
    &__item {
        position: relative;
        max-height: 800px;
        max-height: 1000px;
        height: calc(var(--vh) * 100 - $header-h - 20px) !important;
        padding: 150px 0 60px;
        border-radius: 30px;
        overflow: hidden;
        color: #fff;
        .hero--lg & {
          //  max-height: 1000px;
        }
        .hero__slider &{
            padding-bottom: 150px;
        }
    }
    &__info {
        position: relative;
        z-index: 2;
    }
    &__title {
        font-size: 100px;
        font-family: $font-oddval;
        line-height: 0.9;
        letter-spacing: -0.045em;
        text-transform: uppercase;
        animation: fade-up 1s cubic-bezier(0.35, 0, 0, 1.01);
        .hero__slider & {
            animation: initial;
        }
        .swiper-slide-active & {
            animation: fade-up 1s cubic-bezier(0.35, 0, 0, 1.01);
        }
    }
    &__text {
        font-size: 24px;
        line-height: 1.2;
        margin-top: 24px;
        max-width: 550px;
        animation: fade-up 2s cubic-bezier(0.35, 0, 0, 1.01);
        &.mw {
            max-width: 820px;
        } 
        .hero__slider & {
            animation: initial;
        }
        .swiper-slide-active & {
            animation: fade-up 2s cubic-bezier(0.35, 0, 0, 1.01);
        }
    }
    &__buttons {
        margin-top: 70px; 
        animation: fade-up 3s cubic-bezier(0.35, 0, 0, 1.01);
        .hero__slider & {
            animation: initial;
        }
        .swiper-slide-active & {
            animation: fade-up 3s cubic-bezier(0.35, 0, 0, 1.01);
        }
    }

    @media (min-width: $md-tablet+0.02px) and (max-height: 1000px) {
        .hero--lg &__item {
            max-height: initial;
        }
    }
    @media (min-width: $md-tablet+0.02px) and (max-height: 900px) {
        &__item {
            max-height: initial;
            padding-top: 100px;
        }
    }
    @media (max-width: $md-desktop-md) {
        &__title {
            font-size: 75px;
        }
        &__text {
            font-size: 24px;
            max-width: 450px;
        }
        &__buttons {
            margin-top: 50px;
        }
    }
    @media (max-width: $md-tablet) {
        &__slider-nav {
            padding: 0 30px;
            bottom: 30px;
        }
        &__item {
            border-radius: 20px;
            padding: 100px 30px 0;
            max-height: 600px;
        }
        &__info {
            width: 100%;
        }
        &__title {
            font-size: 50px;
        }
        &__text {
            font-size: 20px;
            max-width: 400px;
        }
    }
    @media (max-width: $md-mobile-lg) {
        margin-bottom: 110px;
        &__inner {
            margin: 0;
        }
        &__slider {
            padding: 0;
            margin: 0;
            position: relative;
            &-nav {
                position: relative;
                left: 0; bottom: 0;
                margin-top: 30px;
            }
            .swiper-nav {
                justify-content: center;
            }
            .swiper-pagination {
                justify-content: center;
                margin: 0;
                padding-bottom: 5px;
                gap: 10px;
                &-bullet {
                    width: 8px; height: 8px;
                    background: #D9D9D9;
                    border-color: #D9D9D9;
                }
                &-bullet-active {
                    border-color: $color-body !important;
                    background: $color-body !important;
                }
            }
            .swiper-button {
                border-color: $color-body;
                color: $color-body;
                background: none;
                display: none;
            }
        }
        &__imgbg {
            border-radius: 20px;
            overflow: hidden;
            position: relative;
            margin: 0 $pd-container 40px;
            // padding-bottom: 64%;
            height: 270px;
            width: calc(100% - 2*$pd-container);
        }
        &__item {
            padding: 0 !important;
            display: block;
            min-height: 1px;
            max-height: initial;
            height: auto !important;
            color: $color-body;
            &-img {
                height: 250px;
                height: initial;
                padding-bottom: 64%;
            }
        }
        &__title {
            font-size: 36px;
            //padding: 0 $pd-container-lg;
        }
        &__text {
            font-size: 18px;
            margin-top: 20px;
        }
        &__buttons {
            margin-top: 40px;
            .btn {
                background: $color-body;
                color: #fff;
            }
        }
    }
    @media (max-width: $md-mobile) {
        &__title {
            font-size: 32px;
        }
        &__text {
            
        }
        &__buttons {
            .btn {
                width: 100%;
            }
        }
    }
    @media (max-width: $md-mobile-smx) {
        &__title {
            font-size: 28px;
        }
    }

}




/**************************************************************

**************************************************************/
.services {
    overflow: hidden;
    &__items {
        position: relative;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding: 0 2px 0 4px;
        padding-bottom: 2px;
    }
    &__item {
        position: relative;
        padding: 26px 20px 33px;
        box-shadow: 0 0 0 2px $color-gray-light;
        // margin: 0 0 -2px -2px;
        background: #fff;
        &:before, &:after, &-lines::before, &-lines::after {
            position: absolute;
            transform: translate(-50%, -50%);
            width: 40px;
            height: 55px;
            background: #fff;
            z-index: 5;
            content: '';
        }
        &:before {
            left: 0; top: 0;
        }
        &:after {
            left: 100%; top: 0;
        }
        &-lines:before {
            left: 0; top: 100%;
        }
        &-lines:after {
            left: 100%; top: 100%;
        }
        &-photo {
            position: relative;
            overflow: hidden;
            padding-bottom: 103%;
            border-radius: 20px;
            margin-bottom: 16px;
            visibility: visible !important;
            &.wow:before {
                position: absolute;
                left: -1%; bottom: 0;
                width: 102%; height: 100%;
                z-index: 5;
                transition: all 1.2s ease-in-out;
                transition-delay: inherit;
                content: '';
                background: #fff;
            }
            &.animated:before {
                transform: translateY(100%);
            }
            img {
                @include photo-cover;
                transition: all 0.3s ease-in-out;
            }
        }
        &:hover &-photo img {
            transform: scale(1.05);
        }
        &-head {
            display: flex;
            justify-content: space-between;
            gap: 20px;
        }
        &-title {
            font-size: 25px;
            text-transform: uppercase;
        }
        &-mlink {
            flex: 0 0 auto;
            width: 50px; height: 50px;
            border-radius: 100px;
            border: 2px solid $color-gray-light;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $color-body;
            transition: all 0.3s ease-in-out;
            &:hover {
                color: #fff;
                border-color: $color-body;
                background: $color-body;
            }
            .icon {
                width: 45%; height: 45%;
            }
        }
        &-price {
            margin-top: 12px;
            font-size: 16px;
            color: $color-gray;
        }
        &-lines {
            
        // border: 2px solid $color-gray-light;
        }
    }

    @media (max-width: $md-tablet) {
        &__items {
            grid-template-columns: 1fr 1fr;
        }
    }
    @media (max-width: $md-mobile-lg) {
        &__items {
            grid-template-columns: 1fr;
        }
        &__item {
            padding: 20px;
            &-title {
                font-size: 24px;
                min-height: 1px;
                margin-bottom: 10px;
            }
            &-price {
                margin-top: 0;
            }
        }
    }
}




/**************************************************************

**************************************************************/
.engaged {
    &__items {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px 20px;
        overflow: hidden;
    }
    &__item {
        position: relative;
        padding: 65px 60px 80px;
        &::before {
            position: absolute;
            bottom: -8px; left: 0;
            border-bottom: 2px solid $color-gray-light;
            width: 0;
            content: '';
            transition: all 1.3s ease-in-out;
        }
        &:nth-child(2n)::before {
            // transition-delay: 0.2s;
            left: auto; right: 0;
        }
        &.animated:before {
            width: calc(100% - 5px);
        }
        &::after {
            position: absolute;
            top: 0; right: -8px;
            border-right: 2px solid $color-gray-light;
            height: 0;
            content: '';
            transition: all 1.3s ease-in-out;
        }
        &.animated::after {
            height: calc(100% - 5px);
        }
        &-title {
            font-size: 25px;
            text-transform: uppercase;
        }
        &-text {
            margin-top: 20px;
            color: #636363;
        }
    }

    @media (max-width: $md-desktop) {
        &__item {
            padding: 40px 30px 50px;
        }
    }
    @media (max-width: $md-tablet) {
        &__item {
            padding: 30px 20px;
            &-title {
                font-size: 22px;
            }
        }
    }
    @media (max-width: $md-mobile-lg) {
        &__items {
            grid-template-columns: 1fr;
            grid-gap: 30px;
            padding: 0;
        }
        &__item {
            padding: 0 0 30px;
            border-bottom: 2px  solid $color-gray-light;
            &::before, &::after {
                display: none;
            }
            &-title {
                font-size: 20px;
            }
        }
    }
}



/**************************************************************

**************************************************************/
.e4steps {
    padding: 200px 0 160px;
    position: relative;
    z-index: 1;
    color: #fff;
    &__imgbg {
        position: absolute;
        left: $pd-container;
        right: $pd-container;
        top: 0; height: 100%;
        border-radius: 30px;
        overflow: hidden;
        z-index: -1;
        background-position: 50%;
        background-size: cover;
        background-attachment: fixed;
        &::before {
            @include abs-all;
            background: linear-gradient(180deg, rgba(33, 33, 33, 0.00) 0%, rgba(33, 33, 33, 0.49) 100%);
            z-index: 1;
            content: '';
        }
        &.v--2::before {
            background: linear-gradient(180deg, rgba(33, 33, 33, 0.55) 0%, rgba(33, 33, 33, 0.26) 100%);
        }
        &.v--3::after {
            @include abs-all;
            background: rgba(33, 33, 33, 0.50);
            z-index: 1;
            content: '';
        }
        img {
            @include photo-cover
        }
    }
    &__head {
        margin-bottom: 110px;
    }
    &__title {
        font-family: $font-oddval;
        font-size: 75px;
        font-weight: 600;
        line-height: 1;
        letter-spacing: -0.045em;
        text-transform: uppercase;
    }
    &__items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 5px;
    }
    &__item {
        position: relative;
        border: 2px solid #fff;
        border-radius: 100%;
        width: 293px; height: 293px;
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        &:hover {
            background: rgba(33, 33, 33, 0.30);
            backdrop-filter: blur(15px);
        }
        &-text {
            font-size: 25px;
            text-transform: uppercase;
            text-align: center;
            padding: 30px;
            @include abs-center;
            width: 100%;
            transition: all 0.3s ease-in-out;
            &.fs--md{
                font-size: 20px;
                text-transform: none;
            }
            &.text--hover {
                opacity: 0;
                visibility: hidden;
            }
            .item--hover:hover &{
                opacity: 0; visibility: hidden;
                &.text--hover {
                    opacity: 1; visibility: visible;
                }
            }
        }
        &-numb {
            font-size: 15px;
            position: absolute;
            top: 30px; left: 50%;
            transform: translateX(-50%);
        }
    }
    &__buttons {
        margin-top: 88px;
    }

    
    @media (min-width: $md-desktop+0.02px) and (max-height: 1000px) { 
        padding: 140px 0 100px;
        &__head {
            margin-bottom: 80px;
        }
    }
    @media (max-width: $md-desktop-md) {
        &__item {
            width: 280px; height: 280px;
        }
    }
    @media (max-width: $md-desktop) {
        padding: 140px 0 100px;
        &__head {
            margin-bottom: 60px;
        }
        &__title {
            font-size: 65px;
        }
        &__item {
            width: 230px; height: 230px;
            &-text {
                padding: 20px;
                font-size: 20px;
                &.fs--md{
                    font-size: 16px;
                }
            }
            &-numb {
                top: 24px;
            }
            
        }
        &__buttons {
            margin-top: 60px;
        }
    }
    @media (max-width: $md-tablet) {
        &__wrap {
            padding: 0 40px;
        }
        &__imgbg {
            border-radius: 20px;
        }
        &__title {
            font-size: 50px;
        }
        &__items {
            justify-content: center;
            gap: 24px;
        }
        &__item {
            
            background: rgba(33, 33, 33, 0.30);
            backdrop-filter: blur(15px);
        }
        &__buttons {
            text-align: center;
        }
    }
    @media (max-width: $md-mobile-lg) {
        padding: 60px 0 24px;
        &__imgbg {
            background-attachment: scroll;
        }
        &__wrap {
            padding: 0 30px;
            padding: 0 20px;
        }
        &__head {
            margin-bottom: 40px;
        }
        &__title {
            font-size: 32px;
            font-size: 28px;
        }
        &__buttons {
            margin-top: 48px;
            .btn {
                width: 100%;
                max-width: 400px;
            }

        }
    }
}




/**************************************************************

**************************************************************/
.advant {
    &__item {
        display: flex;
        gap: 30px;
        padding-bottom: 55px;
        border-bottom: 2px solid $color-gray-light;
        & + & {
            margin-top: 55px;
        }
        &-numb {
            flex: 0 0 auto;
            width: 23%;
            font-size: 30px;
        }
        &-title {
            flex: 0 0 auto;
            width: 270px;
            font-size: 24px;
            line-height: 1.18;
            text-transform: uppercase;
        }
        &-text {
            flex: 1 0 0;
            color: #636363;
        }
    }

    &__row {
        padding-bottom: 50px;
        padding-right: 80px;
        border-bottom: 2px solid $color-gray-light;
        position: relative;
        & + & {
            margin-top: 50px;
        }
        &-inner {
            display: flex;
            gap: 30px;
            cursor: pointer;
        }
        &-head {
            width: 50%;
            flex: 0 0 auto;
            min-height: 50px;
            cursor: pointer;
        }
        &-title {
            max-width: 470px;
            line-height: 1.18;
            font-size: 24px;
            text-transform: uppercase;
        }
        &-plus {
            position: absolute;
            right: 0; top: 0;
            flex: 0 0 auto;
            width: 50px; height: 50px;
            border-radius: 100px;
            border: 2px solid $color-gray-light;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $color-body;
            transition: all 0.3s ease-in-out;
            transform: rotate(-0deg);
            .icon {
                width: 45%; height: 45%;
            }
        }
        &-inner:hover &-plus {
            color: #fff;
            border-color: $color-body;
            background: $color-body;
        }
        &.opened &-plus {
            transform: rotate(90deg);
        }
        &-body {
            flex: 1 0 0;
            max-width: 470px;
            display: none;
            color: #636363;
        }
    }
    @media (max-width: $md-desktop) {
        &__row {
            padding-bottom: 30px;
            & + & {
                margin-top: 30px;
            }
        }
    }
    @media (max-width: $md-tablet) {
        &__item {
            padding-bottom: 40px;
            flex-wrap: wrap;
            & + & {
                margin-top: 40px;
            }
            &-numb {
                width: 100px;
            }
            &-title {
                flex: 1 0 0;
                width: auto;
            }
            &-text {
                width: 100%;
                flex: 0 0 auto;
            }
        }
        &__row {
            padding-right: 0;
            &-inner {
                display: block;
            }
            &-head {
                width: 100%;
                padding: 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 20px;
            }
            &-plus {
                position: static;
            }
            &-title {
                font-size: 22px;
                max-width: 100%;
            }
            &-body {
                padding-top: 20px;
            }
        }
        
    }
    @media (max-width: $md-mobile-lg) {
        &__item {
            padding-bottom: 30px;
            gap: 10px;
            & + & {
                margin-top: 30px;
            }
            &-numb {
                width: 50px;
                font-size: 24px;
            }
            &-title {
                
                font-size: 20px;
            }
        }
        &__row {
            &-head {
                gap: 50px;
            }
            &-title {
                font-size: 20px;
            }
            &-plus {
                width: 46px; height: 46px;
            }
        }
    }
}





/**************************************************************

**************************************************************/
.mabout {
    padding-bottom: 130px;
    // min-height: 670px;
    text-align: center;
    position: relative;
    &__photo {
        position: absolute;
        border-radius: 10px;
        overflow: hidden;
        z-index: -1;
        &.photo--1 {
            left: 0; top: 0;
            width: 153px; height: 153px;
        }
        &.photo--2 {
            left: 70px; bottom: -17%;
            width: 168px; height: 223px;
            transform: translateY(27%);
        }
        &.photo--3 {
            right: 30px; bottom: 27px;
            width: 263px; height: 174px;
        }
        img {
            @include photo-cover;
        }
    }
    &__info {
        position: relative;
        z-index: 1;
        padding-top: 130px;
    }
    &__title {
        font-family: $font-oddval;
        font-size: 80px;
        text-transform: uppercase;
        line-height: 0.9;
    }
    &__buttons {
        margin-top: 50px;
    }

    @media (max-width: $md-desktop-md) {
        &__title {
            font-size: 70px;
        }        
    }
    @media (max-width: $md-tablet) {
        padding-bottom: 80px;
        &__info {
            padding-top: 80px;
        }
        &__title {
            font-size: 50px;
        }   
        &__photo {
            &.photo--1 {
                left: 10px;
                width: 90px; height: 90px;
            }
            &.photo--2 {
                left: 50px; 
                width: 110px; height: 150px;
                transform: translateY(20%);
            }
            &.photo--3 {
                right: 0px; bottom: 10px;
                width: 200px; height: 130px;
            }
        }     
    }
    @media (max-width: $md-mobile-lg) {
        padding: 0;
        &__info {
            padding: 0;
        }
        &__title {
            font-size: 28px;
        }
        &__photo {
            &.photo--1 {
               display: none;
            }
            &.photo--2 {
                display: none;
            }
            &.photo--3 {
                position: relative;
                right: 0px; bottom: 0;
                width: 100%; height: auto;
                padding-bottom: 60%;
                border-radius: 20px;
                margin-top: 24px;
                transform: initial !important;
            }
        }  
        &__buttons {
            margin-top: 24px;
        }
    }
    @media (max-width: $md-mobile-smx) {
        &__title {
            font-size: 24px;
        }
    }
}


/**************************************************************

**************************************************************/
.blog {
    overflow: hidden;
    &__items {
        position: relative;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 0 2px 6px;
    }
    &__item {
        position: relative;
        padding: 24px 20px 32px;
        box-shadow: 0 0 0 2px $color-gray-light;
        margin: 0 0 -2px -2px;
        background: #fff;
        &:before, &:after, &-lines::before, &-lines::after {
            position: absolute;
            transform: translate(-50%, -50%);
            width: 40px;
            height: 55px;
            background: #fff;
            z-index: 5;
            content: '';
        }
        &:before {
            left: 0; top: 0;
        }
        &:after {
            left: 100%; top: 0;
        }
        &-lines:before {
            left: 0; top: 100%;
        }
        &-lines:after {
            left: 100%; top: 100%;
        }
        &-photo {
            position: relative;
            z-index: 10;
            overflow: hidden;
            padding-bottom: 45%;
            border-radius: 20px;
            margin-bottom: 16px;
            visibility: visible !important;
            &.wow:before {
                position: absolute;
                left: -1%; bottom: 0;
                width: 102%; height: 100%;
                z-index: 5;
                transition: all 1.2s ease-in-out;
                transition-delay: inherit;
                content: '';
                background: #fff;
            }
            &.animated:before {
                transform: translateY(100%);
            }
            img {
                @include photo-cover;
                transition: all 0.3s ease-in-out;
            }
        }
        &:hover &-photo img {
            transform: scale(1.05);
        }
        &-head {
            display: flex;
            justify-content: space-between;
            gap: 20px;
        }
        &-title {
            font-size: 24px;
            text-transform: uppercase;
            min-height: 55px;
        }
        &-mlink {
            flex: 0 0 auto;
            width: 50px; height: 50px;
            border-radius: 100px;
            border: 2px solid $color-gray-light;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $color-body;
            transition: all 0.3s ease-in-out;
            &:hover {
                color: #fff;
                border-color: $color-body;
                background: $color-body;
            }
            .icon {
                width: 45%; height: 45%;
            }
        }
        &-props {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-top: 10px;
        }
        &-prop {
            font-size: 14px;
            text-transform: uppercase
        }
        &-lines {
            
        // border: 2px solid $color-gray-light;
        }
    }

    @media (max-width: $md-tablet) {
        &__item {
            padding: 20px 14px 30px;
            &:before, &:after, &-lines::before, &-lines::after {
                width: 30px; height: 40px;
                margin-top: -3px;
            }
            &-photo {
                padding-bottom: 55%;
            }
            &-title {
                font-size: 20px;
                min-height: 1px;
            }
            &-mlink {
                width: 44px; height: 44px;
            }
            &-prop {
                font-size: 12px;
            }
        }
    }
    @media (max-width: $md-mobile-lg) {
        &__items {
            grid-template-columns: 1fr;
        }
    }
}




/**************************************************************

**************************************************************/
.blogd {
    
    &__head {
        margin-bottom: 42px;
        padding-bottom: 42px;
        border-bottom: 2px solid $color-gray-light;
    }
    &__title {
        letter-spacing: -0.045em;
    }
    &__props {
        margin-top: 30px;
        display: flex;
        align-items: center;
        gap: 30px;
    }
    &__prop {
        font-size: 15px;
        text-transform: uppercase;
    }
    &__intro {
        margin-top: 42px;
        font-size: 30px;
    }
    &__photo {
        margin-top: 64px;
        padding-bottom: 60%;
        position: relative;
        overflow: hidden;
        img {
            @include photo-cover
        }
    }
    &__content {
        margin-top: 82px;
    }

    @media (min-width: $md-tablet+0.02px) and (max-height: 800px) {
        &__photo {
            padding-bottom: 52%;
        }
    }

    @media (max-width: $md-desktop) {
        &__head {
            padding-bottom: 30px;
            margin-bottom: 30px;
        }
        &__props {
            margin-top: 24px;
        }
        &__intro {
            font-size: 24px;
            margin-top: 30px;
        }
        &__photo {
            margin-top: 50px;
        }
    }
    @media (max-width: $md-mobile-lg) {
        &__head {
            padding-bottom: 20px;
            margin-bottom: 24px;
        }
        &__prop {
            font-size: 14px;
        }
        &__intro {
            font-size: 20px;
            margin-top: 24px;
        }
        &__photo {
            margin-top: 40px;
        }
    }
}



/**************************************************************

**************************************************************/
.ordform {
    position: relative;
    z-index: 1;
    color: #fff;
    &__imgbg {
        position: absolute;
        left: $pd-container;
        right: $pd-container;
        top: 0; height: 100%;
        border-radius: 30px;
        overflow: hidden;
        z-index: -1;
        background-position: 50%;
        background-size: cover;
        background-attachment: fixed;
        &::before {
            @include abs-all;
            background: linear-gradient(180deg, rgba(33, 33, 33, 0.71) 0%, rgba(33, 33, 33, 0.49) 100%);

            z-index: 1;
            content: '';
        }
        img {
            @include photo-cover
        }
    }
    &__inner {
        padding: 137px 172px;
        border-radius: 30px;
        background: rgba(0, 0, 0, 0.30);
        backdrop-filter: blur(15px);
    }
    &__head {
        margin-bottom: 30px;
    }
    &__title {
        font-family: $font-oddval;
        font-size: 75px;
        font-weight: 600;
        line-height: 1;
        letter-spacing: -0.045em;
        text-transform: uppercase;
    }
    &__subtitle {
        margin-top: 20px;
        font-size: 25px;
        text-transform: uppercase;
        
    }
    &__form {
        width: 85%;
        max-width: 720px;
    }

    @media (max-width: $md-desktop-md) {
        &__inner {
            padding: 100px 130px;
        }
        &__title {
            font-size: 70px;
        }
        &__subtitle {
            font-size: 20px;
        }
    }
    @media (max-width: $md-desktop) {
        &__title {
            font-size: 55px;
        }
        
    }
    @media (max-width: $md-tablet) {
        &__imgbg {
            border-radius: 20px;
        }
        &__inner {
            padding: 50px 30px;
            margin: 0 20px;
            border-radius: 20px;
        }
        &__title {
            font-size: 36px;
        }
        &__subtitle {
            font-size: 18px;
        }
    }
    @media (max-width: $md-tablet-sm) {
        padding: 0;
        &__inner {
            margin: 0;
            padding: 80px 20px;
        }
        &__head {
            text-align: center;
            margin-bottom: 50px;
        }
        &__title {
            font-size: 28px;
        }
        &__subtitle {
            font-size: 20px;
            margin-top: 50px;
        }
        &__form {
            width: 100%;
        }
    }
}




/**************************************************************

**************************************************************/
.blocks {
    & + & {
        margin-top: 150px;
    }
    &__inner {
        display: flex;
        // align-items: center;
        justify-content: space-between;
    }

    &__image {
        flex: 0 0 auto;
        width: 50%;
        max-width: 600px;
        overflow: hidden;
        &-wrap {
            padding-bottom: 80%;
            height: 100%;
            position: relative;
            overflow: hidden;
            border-radius: 20px;
            &.wow:before {
                position: absolute;
                left: 0; bottom: 0;
                width: 102%; height: 100%;
                z-index: 5;
                transition: all 1.2s ease-in-out;
                transition-delay: inherit;
                content: '';
                background: #fff;
            }
            &.animated:before {
                transform: translateX(-100%);
            }
        }
        img {
            @include photo-cover;
        }
    }

    &--reverse &__image {
        order: -1;
        padding-right: 80px;
        &-wrap {
            &.wow::before { 
                left: auto; right: 0;
            }
            &.animated:before {
                transform: translateX(100%);
            }
        }
    }

    &__info {
        flex: 1 0 0;
        padding: 64px 115px 64px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &--reverse &__info {
        max-width: 100%;
    }
    &__box + &__box {
        margin-top: 105px;
    }

    &__title {
        font-size: 24px;
        text-transform: uppercase;
    }
    &__text {
        margin-top: 20px;
        color: #636363;
        
    }
    &__buttons {
        margin-top: 50px;
    }

    @media (max-width: $md-desktop) {
        & + & {
            margin-top: 100px;
        }
        &__info {
            padding: 40px 80px 40px 0;
        }
        &__box + &__box {
            margin-top: 70px;
        }
        &__title {
            font-size: 22px;
        }
    }
    @media (max-width: $md-tablet) {
        & + & {
            margin-top: 40px;
        }
        &__inner {
            flex-direction: column !important;
        }
        &__image {
            // display: none;
            order: 1 !important;
            margin-top: 30px;
            padding: 0 !important;
            width: 100%;
            &-wrap {
                padding-bottom: 70%;
            }
        }
        &__info {
            padding: 0;
        }
        &__box {
            border-bottom: 2px solid $color-gray-light;
            padding-bottom: 30px;
            & + & {
                margin-top: 30px;
            }
        }
    }
    @media (max-width: $md-mobile-lg) {
        &__title {
            font-size: 20px;
        }
    }
}





/**************************************************************

**************************************************************/
.works {
    &__head {
        margin-bottom: 54px;
    }
    &__intro {
        max-width: 920px;
        margin-bottom: 36px;
    }
    &__carousel {
        overflow: hidden;
        border-radius: 20px;
    }
    &__item {
        height: 420px;
        position: relative;
        overflow: hidden;
        border-radius: 20px;
        width: calc(var(--w)*0.3625 - 10px);
        &:nth-child(2n) {
            width: calc(var(--w)*0.6375);
        }
        img {
            @include photo-cover;
        }
    }

    @media (max-width: $md-desktop) {
        &__item {
            height: 300px;
        }
    }
    @media (max-width: $md-tablet) {
        &__head {
            margin-bottom: 30px;
        }
        &__intro {
            
        }
        &__carousel-nav {
            justify-content: center;
        }
    }
    @media (max-width: $md-mobile-lg) {
        &__head {
            margin-bottom: 20px;
        }
        &__item {
            height: 230px;
        }
    }
}




/**************************************************************

**************************************************************/
.faq {
    &__items {
        border-top: 2px solid $color-gray-light;
    }
    &__item {
        padding: 26px 0;
        border-bottom: 2px solid $color-gray-light;
        position: relative;
        &-head {
            display: flex;
            justify-content: space-between;
            gap: 30px;
            cursor: pointer;
        }
        &-title {
            line-height: 1.18;
            font-size: 24px;
            text-transform: uppercase;
            min-height: 50px;
            display: flex;
            align-items: center;
        }
        &-plus {
            flex: 0 0 auto;
            width: 50px; height: 50px;
            border-radius: 100px;
            border: 2px solid $color-gray-light;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $color-body;
            transition: all 0.3s ease-in-out;
            transform: rotate(-0deg);
            .icon {
                width: 45%; height: 45%;
            }
        }
        &-head:hover &-plus {
            color: #fff;
            border-color: $color-body;
            background: $color-body;
        }
        &.opened &-plus {
            transform: rotate(90deg);
        }
        &-body {
            display: none;
            padding-right: 190px;
            padding-top: 10px;
            color: #636363;
        }
    }

    @media (max-width: $md-tablet) {
        &__item {
            &-title {
                font-size: 22px;
            }
            &-body {
                padding-top: 20px;
                padding-right: 100px;
            }
        }
    }
    @media (max-width: $md-mobile-lg) {
        &__item {
            &-title {
                font-size: 20px;
                min-height: 46px;
            }
            &-plus {
                width: 46px; height: 46px;
            }
            &-body {
                padding-right: 0;
            }
        }
    }
}



/**************************************************************

**************************************************************/
.reviews {
    &__slider {
        padding-top: 40px;
        border-top: 2px solid $color-gray-light;
    }
    &__item {
        display: flex;
        gap: 60px;
        &-photo {
            flex: 0 0 auto;
            width: 240px; height: 240px;
            border-radius: 100%;
            position: relative;
            overflow: hidden;
            img {
                @include photo-cover
            }
        }
        &-info {
            max-width: 580px;
        }
        &-name {
            font-size: 24px;
            font-weight: 600;
        }
        &-text {
            font-size: 15px;
            margin-top: 25px;
        }
    }

    @media (max-width: $md-tablet) {
        &__slider-nav {
            justify-content: center;
        }
        &__item {
            gap: 30px;
            &-photo {
                width: 180px; height: 180px;
            }
            &-name {
                font-size: 22px;
            }
            &-text {
                margin-top: 20px;
            }
        }
    }
    @media (max-width: $md-mobile-lg) {
        &__slider {
            padding: 0;
            border: none;
        }
        &__item {
            flex-direction: column;
            gap: 24px;
            &-photo {
                width: 120px; height: 120px;
                margin: 0 auto;
            }
            &-name {
                font-size: 20px;
            }
            &-text {
                font-size: 14px;
            }
        }
    }
}

/**************************************************************
.error404
**************************************************************/
.error404 {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    
    text-align: center;
    &__numb {
        font-size: 96px;
        line-height: 1;
        text-transform: uppercase;
    }
    &__title {
        font-size: 45px;
        text-transform: uppercase;
    }
    &__buttons {
        padding-top: 60px;
    }

    @media (max-width: $md-tablet) {
        &__numb {
            font-size: 76px;
        }
        &__title {
            font-size: 32px;
        }
        &__buttons {
            padding-top: 30px;
        }
    }

    @media (max-width: $md-mobile-lg) {
        &__numb {
            font-size: 64px;
        }
        &__title {
            font-size: 26px;
        }
        &__buttons {
            padding-top: 30px;
        }
    }

}


/**************************************************************

**************************************************************/
.team {
    &__items {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 60px;
    }
    &__item {
        &-photo {
            position: relative;
            padding-bottom: 100%;
            margin-bottom: 24px;
            img {
                @include photo-cover;
            }
        }
        &-info {
            font-size: 15px;
        }
        &-name {
            color: $color-gray;
        }
        &-work {
            margin-top: 5px;
        }
    }

    @media (max-width: $md-desktop) {
        &__items {
            grid-gap: 30px;
        }
    }
    @media (max-width: $md-tablet) {
        &__items {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 60px;
        }
    }
    @media (max-width: $md-tablet-sm) {
        &__items {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 40px;
        }
    }
    @media (max-width: $md-mobile-lg) {
        &__items {
            grid-template-columns: repeat(2, 1fr);
        }
    }
    @media (max-width: $md-mobile) {
        &__items {
            grid-template-columns: 1fr;
            grid-gap: 24px;
        }
        &__item {
            display: flex;
            align-items: center;
            &-photo {
                flex: 0 0 auto;
                margin: 0 16px 0 0;
                width: 110px; height: 110px;
                padding: 0;
            }
            &-info {
                font-size: 20px;
            }
        }
    }
    @media (max-width: $md-mobile-smx) {
        &__item {
            &-photo {
                width: 100px; height: 100px;
            }
            &-info {
                font-size: 16px;
            }
        }
    }
}


/**************************************************************

**************************************************************/
.why {
    &__inner {
        display: flex;
        flex-direction: column;
    }
    &__items {
        position: relative;
        // display: grid;
        // grid-template-columns: 1fr 1fr;
        // justify-content: end;
        // grid-gap: 10px;
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        & + & {
            margin-top: 40px;
        }
        &:first-child {
            order: -2;
        }
        &:not(:last-child) {
            padding-bottom: 30px;
            border-bottom: 2px solid $color-gray-light;
            &::before {
                position: absolute;
                left: 50%; top: 100%;
                transform: translate(-50%, -50%);
                width: 15px; height: 15px;
                background: $color-body;
                border-radius: 100px;
                z-index: 1;
                content: '';
            }
        }
    }
    &__item {
        flex: 0 0 auto;
        width: calc(50% - 5px);
        &-numb {
            font-size: 15px;
            margin-bottom: 20px;
        }
    }
    &__photo {
        order: -1;
        margin: 56px 0 16px;
        border-radius: 30px;
        position: relative;
        overflow: hidden;
        padding-bottom: 36%;
        img {
            @include photo-cover;
        }
    }

    @media (max-width: $md-tablet) {
        &__photo {
            border-radius: 20px;
            padding-bottom: 50%;
        }
        &__items {
            display: block;
            padding: 0 !important;
            border: none !important;
            & + & {
                margin-top: 30px;
            }
            &::before {
                display: none !important;
            }
        }
        &__item {
            width: 100%;
            position: relative;
            padding-bottom: 30px;
            border-bottom: 2px solid $color-gray-light;

            & + & {
                margin-top: 30px;
            }
            &::before {
                position: absolute;
                left: 50%; top: 100%;
                transform: translate(-50%, -50%);
                width: 15px; height: 15px;
                background: $color-body;
                border-radius: 100px;
                z-index: 1;
                content: '';
            }
        }
    }
    @media (max-width: $md-mobile-lg) {
        &__photo {
            order: 5;
            margin: 30px 0 0;
            padding-bottom: 65%;
        }
    }
}


/**************************************************************

**************************************************************/
.ahero {
    position: relative;
    color: #fff;
    & + * {
        margin-top: 120px;
    }
    &__imgbg {
        position: absolute;
        top: 0;
        left: $pd-container-lg;
        right: $pd-container-lg;
        height: 100%;
        border-radius: 30px;
        overflow: hidden;
        &::before {
            // @include abs-all;
            // background: linear-gradient(180deg, rgba(0, 0, 0, 0.40) 60.96%, rgba(0, 0, 0, 0.20) 79.64%);
            // z-index: 1;
            // content: '';
        }
        img {
            @include photo-cover
        }
    }
    &__inner {
        position: relative;
        z-index: 2;
        min-height: calc(var(--vh) * 100 - $header-h - 20px);
        max-height: 900px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 130px 120px 70px;
    }
    &__title {
        font-family: $font-oddval;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 21vw;
        letter-spacing: -0.045em;
        line-height: 1;
        position: relative;
        animation: fade-up 2s cubic-bezier(0.35, 0, 0, 1.01);
        .st {
            font-family: $font;
            font-weight: 500;
            font-size: 5.5vw;
            letter-spacing: 0.38em;
            position: absolute;
            left: 0; top: 100%;
            transform: translate(4%, -20%);
        }
    }
    &__imgt {
        display: flex;
        animation: fade-up 2s cubic-bezier(0.35, 0, 0, 1.01);
        img {
            width: 100%;
        }
    }
    &__intro {
        display: flex;
        justify-content: flex-end;
        font-size: 30px;
        text-transform: uppercase;
        position: relative;
        // transform: translateY(-10%);
        // transform: translateY(-110%);
        top: -85px;
        animation: fade-up 3s cubic-bezier(0.35, 0, 0, 1.01);
        &-inner {
            // width: 44%;
            max-width: 610px;
        }
    }

    @media (max-width: $md-desktop-lg) {
        &__title {
            font-size: 19.7vw;
            .st {
                font-size: 5vw;
            }
        }
        &__intro {
            font-size: 24px;
            // transform: translateY(-100%);
            top: -60px;
            &-inner {
                // width: 40%;
            }
        }
    }
    @media (max-width: $md-desktop-md) {
        &__inner {
            padding-left: 70px;
            padding-right: 70px;
        }
        &__intro {
            font-size: 20px;
            top: -50px;
            &-inner {
                
                // width: 42%;
            }
        }
    }
    @media (max-width: $md-tablet) {
        &__imgbg {
            border-radius: 20px;
        }
        &__inner {
            padding-left: 50px;
            padding-right: 50px;
        }
        &__title {
            .st {
                position: static;
            }
        }
        &__intro {
            display: block;
            margin-top: 60px;
            transform: initial;
            top: 0;
            &-inner {
                width: 100%;
            }
        }
    }
    @media (max-width: $md-tablet-sm) {
        &__imgbg {
            left: $pd-container;
            right: $pd-container;
        }
    }
    @media (max-width: $md-mobile-lg) {
        & + * {
            margin-top: 75px;
        }
        &__inner {
            height: 450px;
            min-height: 1px;
            padding: 100px 35px 60px;
        }
        &__intro {
            font-size: 18px;
        }
    }
}


/**************************************************************

**************************************************************/



/**************************************************************

**************************************************************/