@use "sass:math";

@mixin input-placeholder($class, $color, $opacity:0.6) {
    #{$class}::-webkit-input-placeholder {color: $color;opacity: $opacity;}
    #{$class}::-o-input-placeholder{color: $color;opacity: $opacity;}
    #{$class}::-moz-input-placeholder{ color: $color;opacity: $opacity;}
    #{$class}:-moz-input-placeholder{  color: $color;opacity: $opacity;}
    #{$class}::-ms-input-placeholder{  color: $color;opacity: $opacity;}
    #{$class} input-placeholder {  color: $color;opacity: $opacity;}
    #{$class}::placeholder {color: $color;opacity: $opacity;}
    #{$class}:placeholder-shown {color: $color;opacity: $opacity;}
}


@mixin abs-all {
    position: absolute;
    left: 0; top: 0;
    width: 100%; height: 100%;
}
@mixin abs-center {
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%)
}

@mixin photo-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

@function lh($lh, $fs) {
    @return #{math.div($lh, $fs)};
}