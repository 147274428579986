
/**************************************************************
popup
**************************************************************/
.popup {
    position: fixed;
    top: 0; left: 0;
    width: 100%; height: 100%;
    overflow: auto;
    display: none;
    z-index: 999;
    &__content {
        width: 100%;
        min-height: 100%;
        overflow: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px 15px;
        position: relative;
        z-index: 1;
    }
    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: #232730;
        opacity: 0.7;
    }
    &__container {
        position: relative;
        width: 100%; max-width: 510px;
        padding: 60px 30px 70px;
        background: #2B2D38;
        overflow: hidden;
        text-align: center;
        // display: flex;
        // align-items: center;
    }
    &__chead {
        position: sticky;
        top: 0;
        
    }
    &__close {
        position: absolute;
        // right: 85px; top: 75px;
        width: 60px; height: 60px;
        color: $color-gray;
        cursor: pointer;
        display: flex;
        z-index: 99;
        
        top: 0; left: 100%;
        margin-left: 84px;
        &:hover {
            color: $color-body;
        }
        .icon {
            width: 100%; height: 100%;
        }
    }

    &__allcont {
        min-height: 100%;
        width: 100%;
        background-size: 100%;
        position: relative;
        display: flex;
    }
    &__allimgbg {
        @include abs-all;
        img {
            @include photo-cover;
        }
    }
    &__allbox {
        position: absolute;
        top: 0; right: 0;
        height: calc(var(--vh) * 100);
        width: 72%;
        overflow: auto;
        padding: 75px 240px 75px 85px;
        background: #fff;
        z-index: 5;
    }

    &__head {
        margin-bottom: 48px;
    }
    &__title {
        font-family: $font-oddval;
        font-size: 75px;
        font-weight: 600;
        letter-spacing: -0.045em;
        line-height: 1;
        text-transform: uppercase;
    }
    &__intro {
        font-size: 25px;
        text-transform: uppercase;
        margin-top: 24px;
    }
    
    &__form {
        width: 90%;
        max-width: 970px;
    }

    @media (max-width: $md-desktop-lg) {
        &__close {
            // right: 60px; top: 50px;
            margin-left: 60px;
            width: 50px; height: 50px;
        }
    
        &__allbox {
            padding: 50px 220px 50px 60px;
        }
    
        &__head {
            margin-bottom: 40px;
        }
        &__title {
            font-size: 60px;
        }
        &__intro {
            font-size: 22px;
        }
    }
    @media (max-width: $md-desktop-md) {
        &__title {
            font-size: 54px;
        }
    }
    @media (max-width: $md-desktop) {
        &__allbox {
            width: 100%;
        }
    }
    @media (max-width: $md-tablet) {
        &__allbox {
            padding: 50px 30px;
        }
        &__chead {
            margin-bottom: 35px;
            position: relative;
        }
        &__close {
            position: static;
            margin: 0 auto;
            width: 50px; height: 50px;
        }
        &__head {
            text-align: center;
        }
        &__title {
            font-size: 40px;
        }
        &__intro {
            font-size: 20px;
        }
        &__form {
            width: 100%;
        }
    }
    @media (max-width: $md-mobile-lg) {
        &__allbox {
            padding: 40px $pd-container;
        }
        &__close {
            width: 35px; height: 35px;
        }
        &__title {
            font-size: 24px;
        }
        &__intro {
            font-size: 18px;
        }
    }
}