/**************************************************************
header
**************************************************************/
.header {
    position: fixed;
    top: 0; left: 0;
    width: 100%;
    z-index: 500;
    background: #fff;
    &.fixed {
        border-bottom: 1px solid $color-gray-light;
    }
    
    &__container {
        max-width: calc(100% - 2*$pd-container-lg);
    }
    &__wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: $header-h;
        padding: 10px 0;
        transition: all 0.3s ease-in-out;
    }
    &.fixed &__wrap {
        height: $header-h - 20px;

    }
    &__logo {
        width: 200px;
        transition: all 0.3s ease-in-out;
        img {
            max-height: 50px;
            max-width: 100%;
            transition: all 0.3s ease-in-out;
        }
    }
    &.fixed &__logo {
       
    }
    &.fixed &__logo img {
        max-height: 40px;
    }

    
    &__menu {
        ul {
            display: flex;
            align-items: center;
            gap: 50px;
        }
        li {
        }
        li a {
            font-size: 18px; color: $color-body;
            text-transform: uppercase;
            transition:  all 0.3s ease-in-out;
            display: flex;
            align-items: center;
            &:hover,
            &.active {
                color: $color-body-hover;
            }
        }
        li.active > a {
            color: $color-body-hover;
        }
        li .icon {
            font-size: 7px;
            margin-left: 7px;
        }
    }

    &__action {
        display: flex;
        align-items: center;
        &-item {
            margin-left: 24px;
        }
        .btn {
            min-width: 220px;
        }
        &-btn {
            position: relative;
            width: 50px; height: 50px;
            background: $color-body;
            transition: background 0.3s ease-in-out;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100px;
            border: 2px solid $color-body;
            > * {
                max-width: 80%;
                max-height: 80%;
            } 
            img {
                @include abs-center;
                transition: all 0.3s ease-in-out;
            }
            .img-hover {
                opacity: 0;
            }
            @media (any-hover: hover) {
                &:hover {
                    background: #fff;
                    img {
                        opacity: 0;
                    }
                    .img-hover {
                        opacity: 1;
                    }
                }
            }               
            
        }
    }

    &__burger {
        display: none;
    }
	
    @media (max-width: $md-tablet) {
        &__action {
            margin-left: auto;
        }
        &__menu {
            display: none;
        }
        &__burger {
            display: flex !important;
            cursor: pointer;
            position: relative;
            margin-left: 24px;
            img {
                transition: all 0.3s ease-in-out;
            }
            .img-close {
                position: absolute;
                left: 50%; top: 50%;
                transform: translate(-50%, -50%);
                opacity: 0; visibility: hidden;
            }
            &.active .img-close  {
                opacity: 1; visibility: visible;
            }
            &.active .img-open  {
                opacity: 0; visibility: hidden;
            }
        }
        
        &__menu {
            ul {
                flex-direction: column;
                // align-items: flex-start;
            }
            li {
                margin: 5px 0;
            }
            li a {
                font-size: 14px; 
                padding: 6px 0;
                display: block;
                letter-spacing: 0.05em;
            }
        }
        &__action-item--search {
            display: none;
        }
    }

    @media (max-width: $md-tablet-sm) {
        &__container {
            max-width: 100%;
        }
        &__wrap {
            height: $header-h-m !important;
        }
        &__logo {
            width: 120px;
            margin: 0 auto;
            img {
                max-height: 40px;
            }
        }
        &__action {
            margin: 0;
            &-item {
                margin: 0;
            }
            .item--btn {
                display: none;
            }
            &-btn {
                width: 36px; height: 36px;
                > * {
                    max-width: 16px; max-height: 16px;
                }
            }
        }
        &__burger {
            order: -1;
            margin: 0;
            width: 36px; height: 36px;
            img {
                max-width: 80%;
                max-height: 80%;
            }
        }
    }
}

@import "components/navfix"