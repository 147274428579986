@import url("../fonts/fonts.css");
* {
  margin: 0;
  padding: 0;
  outline: none;
}

html, body, div, span, h1, h2, h3, h4, h5, h6, p, em, img, strong, sub, sup, b, u, i, dl, dt, dd, ol, ul, li, fieldset, form, label, table, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  font-size: 100%;
}

header, nav, section, article, aside, footer {
  display: block;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

html {
  height: 100%;
}

body {
  position: relative;
  height: 100%;
  font-family: "Jost", sans-serif;
  color: #393939;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.3;
  background: #FFF;
}
@media (max-width: 1219.98px) {
  body {
    font-size: 18px;
  }
}
@media (max-width: 609.98px) {
  body {
    font-size: 16px;
  }
}

body *,
body *:before,
body *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

img {
  border: none;
  max-width: 100%;
  vertical-align: bottom;
}

ul {
  list-style: none;
}

a {
  color: #393939;
  text-decoration: none;
  margin: 0;
  padding: 0;
}

.clear {
  clear: both;
  height: 0;
  overflow: hidden;
  font-size: 0;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.container--sm {
  max-width: 830px;
}
@media screen and (max-width: 1439.98px) {
  .container {
    max-width: 1140px;
  }
  .container--sm {
    max-width: 830px;
  }
}
@media screen and (max-width: 1219.98px) {
  .container {
    max-width: 960px;
  }
  .container--sm {
    max-width: 830px;
  }
}
@media screen and (max-width: 1023.98px) {
  .container {
    max-width: 710px;
  }
}
@media screen and (max-width: 767.98px) {
  .container {
    max-width: 100%;
    padding: 0 16px;
  }
}
.wrapper {
  min-height: 100%;
  width: 100%;
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  padding-top: 90px;
}
@media (max-width: 767.98px) {
  .wrapper {
    padding-top: 60px;
  }
}

.content__wrapper {
  flex: 1 0 auto;
}
.content__wrapper.h-auto {
  flex: 0 0 auto;
}

.pd__header {
  padding-top: 90px;
}

@media (max-width: 1699.98px) {
  .hide-desktop-lg {
    display: none !important;
  }
}

@media (max-width: 1439.98px) {
  .hide-desktop-md {
    display: none !important;
  }
}

@media (max-width: 1219.98px) {
  .hide-desktop {
    display: none !important;
  }
}

@media (max-width: 1023.98px) {
  .hide-tablet {
    display: none !important;
  }
}

@media (max-width: 767.98px) {
  .hide-tablet-sm {
    display: none !important;
  }
}

@media (max-width: 609.98px) {
  .hide-mobile-lg {
    display: none !important;
  }
}

@media (min-width: 1220px) {
  .show-desktop {
    display: none !important;
  }
}

@media (min-width: 1024px) {
  .show-tablet {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .show-tablet-sm {
    display: none !important;
  }
}

@media (min-width: 610px) {
  .show-mobile-lg {
    display: none !important;
  }
}

.noscroll {
  overflow: hidden;
}

.nowrap {
  white-space: nowrap;
}

.wow {
  visibility: hidden;
}
@media (max-width: 1023.98px) {
  .wow {
    visibility: visible !important;
    -webkit-animation: none !important;
    animation: none !important;
  }
}

@-webkit-keyframes fade-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fade-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes zoomOutBG {
  0% {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
}
@keyframes zoomOutBG {
  0% {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
}
.content__text > * {
  margin-bottom: 20px;
}
.content__text > *:last-child {
  margin-bottom: 0;
}
.content__text > *:first-child {
  margin-top: 0;
}
.content__text a {
  text-decoration: underline;
}
.content__text a:hover {
  text-decoration: none;
}
.content__text h1, .content__text h2, .content__text h3, .content__text h4, .content__text h5 {
  text-transform: uppercase;
  line-height: 1.1;
}
.content__text h1 {
  font-size: 48px;
  margin: 45px 0;
}
.content__text h2 {
  font-size: 45px;
  margin: 25px 0;
}
.content__text h3 {
  font-size: 36px;
  margin: 20px 0;
}
.content__text h4 {
  font-size: 30px;
  margin: 15px 0;
}
.content__text h5 {
  font-size: 22px;
  margin: 10px 0;
}
.content__text ul,
.content__text ol {
  padding-left: 26px;
}
.content__text ol {
  list-style-type: none;
  counter-reset: num;
}
.content__text li {
  position: relative;
}
.content__text li + li {
  margin-top: 15px;
}
.content__text ul li:before {
  position: absolute;
  top: 10px;
  right: 100%;
  margin-right: 11px;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: #393939;
  content: "";
}
.content__text ol li:before {
  content: counter(num) ".";
  counter-increment: num;
  display: inline-block;
  position: absolute;
  top: 0px;
  right: 100%;
  margin-right: 8px;
  text-align: right;
}
.content__text img {
  margin: 80px 0;
}
@media (max-width: 1219.98px) {
  .content__text h1 {
    font-size: 40px;
  }
  .content__text h2 {
    font-size: 36px;
  }
  .content__text h3 {
    font-size: 30px;
  }
  .content__text h4 {
    font-size: 24px;
  }
  .content__text h5 {
    font-size: 20px;
  }
}
@media (max-width: 767.98px) {
  .content__text > * {
    margin-bottom: 15px;
  }
  .content__text h1 {
    font-size: 28px;
  }
  .content__text h2 {
    font-size: 24px;
  }
  .content__text h3 {
    font-size: 20px;
  }
  .content__text h4 {
    font-size: 18px;
  }
  .content__text h5 {
    font-size: 16px;
  }
  .content__text ul {
    padding-left: 17px;
  }
  .content__text ul li:before {
    width: 4px;
    height: 4px;
    top: 7px;
  }
  .content__text img {
    margin: 40px 0;
  }
}

.section__mglr {
  margin: 0 20px;
}
.section__head {
  margin-bottom: 72px;
}
.section__head.hcols {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}
.section__title {
  font-size: 48px;
  text-transform: uppercase;
}
.text--center .section__intro {
  margin-left: auto;
  margin-right: auto;
}
.section__buttons {
  margin-top: 68px;
  text-align: center;
}
.section__mg {
  margin: 140px 0;
}
.section__mg--lg {
  margin: 250px 0;
}
.section__mg--md {
  margin: 185px 0;
}
.section__mgt {
  margin-top: 140px;
}
.section__mgt--lg {
  margin-top: 250px;
}
.section__mgt--md {
  margin-top: 185px;
}
.section__mgb {
  margin-bottom: 140px;
}
.section__mgb--lg {
  margin-bottom: 250px;
}
.section__mgb--md {
  margin-bottom: 185px;
}
.section__first {
  margin-top: 100px;
}
.section__pd {
  padding: 100px 0;
}
.section__pdt {
  padding-top: 70px;
}
.section__tab {
  display: none;
}
.section__tab.show {
  display: block;
}
@media (max-width: 1219.98px) {
  .section__head {
    margin-bottom: 60px;
  }
  .section__title {
    font-size: 40px;
  }
  .section__buttons {
    margin-top: 50px;
  }
  .section__mg {
    margin: 125px 0;
  }
  .section__mg--lg {
    margin: 160px 0;
  }
  .section__mg--md {
    margin: 150px 0;
  }
  .section__mgt {
    margin-top: 125px;
  }
  .section__mgt--lg {
    margin-top: 160px;
  }
  .section__mgt--md {
    margin-top: 150px;
  }
  .section__mgb {
    margin-bottom: 125px;
  }
  .section__mgb--lg {
    margin-bottom: 160px;
  }
  .section__mgb--md {
    margin-bottom: 150px;
  }
  .section__first {
    margin-top: 90px;
  }
  .section__pd {
    padding: 80px 0;
  }
  .section__pdt {
    padding-top: 70px;
  }
}
@media (max-width: 1023.98px) {
  .section__head.hcols {
    flex-direction: column;
    gap: 20px;
  }
}
@media (max-width: 767.98px) {
  .section__head {
    margin-bottom: 50px;
  }
  .section__title {
    font-size: 28px;
  }
  .section__title br {
    display: none;
  }
  .section__buttons {
    margin-top: 34px;
  }
  .section__buttons .btn {
    width: 100%;
    max-width: 400px;
  }
  .section__mg {
    margin: 110px 0;
  }
  .section__mg--lg {
    margin: 110px 0;
  }
  .section__mg--md {
    margin: 110px 0;
  }
  .section__mgt {
    margin-top: 110px;
  }
  .section__mgt--lg {
    margin-top: 110px;
  }
  .section__mgt--md {
    margin-top: 110px;
  }
  .section__mgb {
    margin-bottom: 110px;
  }
  .section__mgb--lg {
    margin-bottom: 110px;
  }
  .section__mgb--md {
    margin-bottom: 110px;
  }
  .section__first {
    margin-top: 40px;
  }
  .section__pd {
    padding: 60px 0;
  }
  .section__pdt {
    padding-top: 70px;
  }
}
.text--center {
  text-align: center;
}

.text--white {
  color: white;
}

.text--under {
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-thickness: 1px;
}

.icon {
  stroke: currentColor;
  fill: none;
  color: inherit;
  font-size: inherit;
  transition: all 0.3s ease-in-out;
  flex: 0 0 auto;
}
.icon--fill {
  fill: currentColor;
  stroke: initial;
}
.icon path {
  stroke: currentColor;
  color: inherit;
  font-size: inherit;
  transition: all 0.3s ease-in-out;
}

.social__items {
  display: flex;
  align-items: center;
  gap: 10px;
}
.social__item {
  width: 135px;
  height: 135px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #E5E5E5;
  border-radius: 100px;
  position: relative;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}
.social__item:hover {
  background: #E5E5E5;
}
.social__item::after {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 48%;
  height: 48%;
  background: #393939;
  border-radius: 100%;
  z-index: -1;
  content: "";
}
.social__item img {
  width: 27px;
}
.social .icon {
  width: 19px;
  height: 19px;
}
@media (max-width: 1219.98px) {
  .social__items {
    gap: 10px;
  }
  .social__item {
    width: 90px;
    height: 90px;
  }
  .social__item::after {
    width: 57%;
    height: 57%;
  }
  .social__item img {
    width: 20px;
  }
}
@media (max-width: 767.98px) {
  .social__items {
    gap: 19px;
  }
  .social__item {
    width: 70px;
    height: 70px;
  }
  .social__item img {
    width: 15px;
  }
}

.link {
  font-size: 20px;
  line-height: 1.35;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
  display: inline-flex;
  align-items: center;
}
.link .icon {
  width: 20px;
  height: 20px;
  margin-left: 8px;
}
.link__more .close_t {
  display: none;
}
.link__more.opened .open_t {
  display: none;
}
.link__more.opened .close_t {
  display: block;
}
@media (max-width: 1219.98px) {
  .link {
    font-size: 16px;
  }
}

/**************************************************************
.navs
**************************************************************/
.navs {
  margin-bottom: 70px;
  padding-bottom: 30px;
  border-bottom: 2px solid #E5E5E5;
}
.navs__list ul {
  display: flex;
  align-items: center;
}
.navs__list li {
  margin-right: 30px;
  display: flex;
  align-items: center;
}
.navs__list li:first-child:after {
  width: 6px;
  height: 6px;
  background: #E5E5E5;
  border-radius: 100px;
  margin-left: 30px;
  content: "";
}
.navs__list li a {
  color: #393939;
  text-transform: uppercase;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
}
.navs__list li a:hover {
  color: #000;
}
.navs__list li a.active {
  font-weight: 600;
}
.navs__list li.current a {
  font-weight: 600;
}
/**************************************************************
pagination
**************************************************************/
.pagination {
  margin-top: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination .page-item {
  margin: 0 12px;
}
.pagination .page-link {
  border-radius: 100px;
  border: 2px solid #E5E5E5;
  background: none;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  font-size: 20px;
  color: #868686;
  font-weight: 600;
}
.pagination .page-link:hover {
  color: #fff !important;
  background: #393939;
  border-color: #393939;
}
.pagination .page-link .icon {
  width: 32%;
}
.pagination .page-item.active .page-link {
  color: #393939 !important;
  pointer-events: none;
}
.pagination .page-item.disabled {
  display: none;
}
.pagination .page-item.disabled .page-link {
  cursor: default;
  background: none;
}
.pagination .page-next .page-link, .pagination .page-prev .page-link {
  color: #393939;
}
.pagination .page-next .icon {
  transform: translateX(2px);
}
.pagination .page-prev .icon {
  transform: translateX(-1px);
}
.pagination .page-last, .pagination .page-first {
  display: none;
}
@media (max-width: 1023.98px) {
  .pagination {
    margin-top: 40px;
  }
  .pagination .page-item {
    margin: 0 5px;
  }
  .pagination .page-link {
    background: none;
    font-size: 14px;
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 609.98px) {
  .pagination {
    margin-top: 32px;
  }
  .pagination .page-item {
    display: none;
  }
  .pagination .page-prev, .pagination .page-next, .pagination .page-item.active, .pagination .page-item.active + .page-item, .pagination .page-item.active + .page-item + .page-item {
    display: block;
  }
}

/**************************************************************
swiper
**************************************************************/
.swiper-slide {
  height: auto;
}

.swiper-pagination {
  display: flex;
  gap: 15px;
}
.swiper-pagination-bullet {
  margin: 0 !important;
  width: 15px;
  height: 15px;
  border: 2px solid #E5E5E5;
  background: #E5E5E5;
  transition: all 0.3s ease-in-out;
  opacity: 1;
}
.swiper-pagination-bullet-active {
  background: none !important;
}
.swiper-pagination--white .swiper-pagination-bullet {
  border-color: #fff;
  background: #fff;
}
.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-button {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  color: #393939;
  border-radius: 100%;
  border: 2px solid #E5E5E5;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.swiper-button:after {
  display: none;
}
.swiper-button .icon {
  width: 32%;
  position: relative;
}
.swiper-button-next .icon {
  transform: translateX(2px);
}
.swiper-button-prev .icon {
  transform: translateX(-1px);
}
.swiper-button--white {
  color: #fff;
  border-color: #fff;
}
.swiper-button:hover {
  background: #393939;
  border-color: #393939;
  color: #fff;
}

.swiper-scrollbar {
  background: none;
}

.swiper-nav {
  display: flex;
  align-items: center;
  gap: 24px;
}
.swiper-nav .swiper-button {
  position: static;
  transform: initial;
}
.swiper-nav .swiper-pagination {
  position: static;
  width: auto;
}

/**************************************************************
forms
**************************************************************/
.input::-webkit-input-placeholder {
  color: #868686;
  opacity: 1;
}

.input::-o-input-placeholder {
  color: #868686;
  opacity: 1;
}

.input::-moz-input-placeholder {
  color: #868686;
  opacity: 1;
}

.input:-moz-input-placeholder {
  color: #868686;
  opacity: 1;
}

.input::-ms-input-placeholder {
  color: #868686;
  opacity: 1;
}

.input input-placeholder {
  color: #868686;
  opacity: 1;
}

.input::placeholder {
  color: #868686;
  opacity: 1;
}

.input:placeholder-shown {
  color: #868686;
  opacity: 1;
}

.input:focus::-webkit-input-placeholder {
  color: transparent;
  opacity: 0;
}

.input:focus::-o-input-placeholder {
  color: transparent;
  opacity: 0;
}

.input:focus::-moz-input-placeholder {
  color: transparent;
  opacity: 0;
}

.input:focus:-moz-input-placeholder {
  color: transparent;
  opacity: 0;
}

.input:focus::-ms-input-placeholder {
  color: transparent;
  opacity: 0;
}

.input:focus:input-placeholder {
  color: transparent;
  opacity: 0;
}

input[placeholder] {
  text-overflow: ellipsis;
}

input::-moz-placeholder {
  text-overflow: ellipsis;
}

input:-moz-placeholder {
  text-overflow: ellipsis;
}

input:-ms-input-placeholder {
  text-overflow: ellipsis;
}

input, button {
  -webkit-appearance: none;
}

.input {
  width: 100%;
  height: 50px;
  padding: 0 14px;
  font-family: "Jost", sans-serif;
  font-size: 20px;
  color: #393939;
  text-transform: uppercase;
  background: none;
  border: 1px solid #E5E5E5;
  border-radius: 10px;
  outline: none;
}
.input--line {
  border: none;
  border-bottom: 1px solid #C4C4C4;
  border-radius: 0;
  height: 50px;
  padding: 0 0 10px;
}
.input--white {
  border-color: #FFF;
  color: #FFF;
}
.input--white::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
  opacity: 1;
}
.input--white::-o-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
  opacity: 1;
}
.input--white::-moz-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
  opacity: 1;
}
.input--white:-moz-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
  opacity: 1;
}
.input--white::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
  opacity: 1;
}
.input--white input-placeholder {
  color: rgba(255, 255, 255, 0.9);
  opacity: 1;
}
.input--white::placeholder {
  color: rgba(255, 255, 255, 0.9);
  opacity: 1;
}
.input--white:placeholder-shown {
  color: rgba(255, 255, 255, 0.9);
  opacity: 1;
}
.input.error {
  border-color: #b40202 !important;
  color: #b40202;
}
.input.error::-webkit-input-placeholder {
  color: #b40202;
  opacity: 0.6;
}
.input.error::-o-input-placeholder {
  color: #b40202;
  opacity: 0.6;
}
.input.error::-moz-input-placeholder {
  color: #b40202;
  opacity: 0.6;
}
.input.error:-moz-input-placeholder {
  color: #b40202;
  opacity: 0.6;
}
.input.error::-ms-input-placeholder {
  color: #b40202;
  opacity: 0.6;
}
.input.error input-placeholder {
  color: #b40202;
  opacity: 0.6;
}
.input.error::placeholder {
  color: #b40202;
  opacity: 0.6;
}
.input.error:placeholder-shown {
  color: #b40202;
  opacity: 0.6;
}

textarea.input {
  line-height: normal;
  padding: 10px 15px;
  resize: none;
  height: 100px;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 250px;
  height: 50px;
  padding: 0 25px;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  font-family: "Jost", sans-serif;
  text-align: center;
  text-transform: uppercase;
  background: #393939;
  border-radius: 100px;
  border: 2px solid #393939;
  cursor: pointer;
  outline: none;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}
@media (any-hover: hover) {
  .btn:hover {
    background: #fff;
    color: #393939;
  }
}
.btn img, .btn svg {
  margin-left: 10px;
}
.btn--white {
  background: #fff;
  border-color: #fff;
  color: #393939;
}
@media (any-hover: hover) {
  .btn--white:hover {
    background: #393939;
    border-color: #393939;
    color: #fff;
  }
}
.btn--bd {
  background: none;
  border: 2px solid #393939;
  color: #393939;
}
@media (any-hover: hover) {
  .btn--bd:hover {
    background: #393939;
    color: #fff;
  }
}
.btn--wall {
  width: 100%;
}
@media (max-width: 609.98px) {
  .btn--m-wall {
    width: 100%;
    max-width: 400px;
  }
}

.form__input {
  margin-bottom: 45px;
}
.form__submit {
  margin-top: 70px;
}
.form__submit .btn {
  min-width: 290px;
}
.form__connection {
  margin: 10px 0px 15px;
}
.form__connection-title {
  text-align: center;
  margin-bottom: 12px;
  color: #666666;
  font-size: 18px;
  letter-spacing: 0.03em;
}
.form__connection-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -15px;
}
.form__connection-item {
  margin: 5px 15px 10px;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.05em;
  color: #525252;
  cursor: pointer;
  position: relative;
  border: none;
  display: flex;
  align-items: center;
}
.form__policy {
  margin-top: 13px;
  font-size: 12px;
  color: #868686;
}
.form__policy.policy--white {
  color: #E5E5E5;
}
.form__input + .form__policy {
  margin-top: -30px;
}
.form__policy a {
  color: inherit;
  text-decoration: underline;
}
.form__policy a:hover {
  text-decoration: none;
}
.form__rchecks {
  display: flex;
  gap: 35px;
  margin-bottom: 64px;
}
.form__rch {
  display: flex;
  flex: 1 0 0;
  cursor: pointer;
}
.form__rch input {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}
.form__rch-wrap {
  width: 100%;
  border-radius: 30px;
  border: 2px solid #E5E5E5;
  height: 235px;
  padding: 26px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
  font-size: 25px;
  text-transform: uppercase;
  transition: all 0.15s ease-in-out;
}
.form__rch-wrap:hover {
  border-color: #393939;
}
:checked + .form__rch-wrap {
  color: #fff;
  background: #393939;
  border-color: #393939;
}
.form__rch-icon {
  height: 35px;
  position: relative;
}
.form__rch-icon img {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  transition: all 0.15s ease-in-out;
}
.form__rch-icon .img-active {
  opacity: 0;
}
:checked + .form__rch-wrap .form__rch-icon img {
  opacity: 0;
}
:checked + .form__rch-wrap .form__rch-icon .img-active {
  opacity: 1;
}
@media (max-width: 1699.98px) {
  .form__rchecks {
    margin-bottom: 50px;
    gap: 20px;
  }
  .form__rch-wrap {
    height: 200px;
    font-size: 20px;
  }
}
@media (max-width: 1219.98px) {
  .form__input {
    margin-bottom: 30px;
  }
  .form__submit {
    margin-top: 40px;
  }
  .form__input + .form__policy {
    margin-top: -15px;
  }
}
@media (max-width: 1023.98px) {
  .form__rch-wrap {
    border-radius: 20px;
  }
}
@media (max-width: 609.98px) {
  .form__rchecks {
    flex-direction: column;
  }
  .form__rch-wrap {
    padding: 26px 20px;
    border-radius: 10px;
  }
  .form__rch-icon {
    height: 28px;
  }
  .form__submit {
    text-align: center;
  }
  .form__submit .btn {
    width: 100%;
    max-width: 400px;
    min-width: 200px;
  }
}

/**************************************************************
popup
**************************************************************/
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: none;
  z-index: 999;
}
.popup__content {
  width: 100%;
  min-height: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 15px;
  position: relative;
  z-index: 1;
}
.popup__bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #232730;
  opacity: 0.7;
}
.popup__container {
  position: relative;
  width: 100%;
  max-width: 510px;
  padding: 60px 30px 70px;
  background: #2B2D38;
  overflow: hidden;
  text-align: center;
}
.popup__chead {
  position: sticky;
  top: 0;
}
.popup__close {
  position: absolute;
  width: 60px;
  height: 60px;
  color: #868686;
  cursor: pointer;
  display: flex;
  z-index: 99;
  top: 0;
  left: 100%;
  margin-left: 84px;
}
.popup__close:hover {
  color: #393939;
}
.popup__close .icon {
  width: 100%;
  height: 100%;
}
.popup__allcont {
  min-height: 100%;
  width: 100%;
  background-size: 100%;
  position: relative;
  display: flex;
}
.popup__allimgbg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.popup__allimgbg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.popup__allbox {
  position: absolute;
  top: 0;
  right: 0;
  height: calc(var(--vh) * 100);
  width: 72%;
  overflow: auto;
  padding: 75px 240px 75px 85px;
  background: #fff;
  z-index: 5;
}
.popup__head {
  margin-bottom: 48px;
}
.popup__title {
  font-family: "Oddval", sans-serif;
  font-size: 75px;
  font-weight: 600;
  letter-spacing: -0.045em;
  line-height: 1;
  text-transform: uppercase;
}
.popup__intro {
  font-size: 25px;
  text-transform: uppercase;
  margin-top: 24px;
}
.popup__form {
  width: 90%;
  max-width: 970px;
}
@media (max-width: 1699.98px) {
  .popup__close {
    margin-left: 60px;
    width: 50px;
    height: 50px;
  }
  .popup__allbox {
    padding: 50px 220px 50px 60px;
  }
  .popup__head {
    margin-bottom: 40px;
  }
  .popup__title {
    font-size: 60px;
  }
  .popup__intro {
    font-size: 22px;
  }
}
@media (max-width: 1439.98px) {
  .popup__title {
    font-size: 54px;
  }
}
@media (max-width: 1219.98px) {
  .popup__allbox {
    width: 100%;
  }
}
@media (max-width: 1023.98px) {
  .popup__allbox {
    padding: 50px 30px;
  }
  .popup__chead {
    margin-bottom: 35px;
    position: relative;
  }
  .popup__close {
    position: static;
    margin: 0 auto;
    width: 50px;
    height: 50px;
  }
  .popup__head {
    text-align: center;
  }
  .popup__title {
    font-size: 40px;
  }
  .popup__intro {
    font-size: 20px;
  }
  .popup__form {
    width: 100%;
  }
}
@media (max-width: 609.98px) {
  .popup__allbox {
    padding: 40px 16px;
  }
  .popup__close {
    width: 35px;
    height: 35px;
  }
  .popup__title {
    font-size: 24px;
  }
  .popup__intro {
    font-size: 18px;
  }
}

/**************************************************************
header
**************************************************************/
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 500;
  background: #fff;
}
.header.fixed {
  border-bottom: 1px solid #E5E5E5;
}
.header__container {
  max-width: calc(100% - 40px);
}
.header__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  padding: 10px 0;
  transition: all 0.3s ease-in-out;
}
.header.fixed .header__wrap {
  height: 70px;
}
.header__logo {
  width: 200px;
  transition: all 0.3s ease-in-out;
}
.header__logo img {
  max-height: 50px;
  max-width: 100%;
  transition: all 0.3s ease-in-out;
}
.header.fixed .header__logo img {
  max-height: 40px;
}
.header__menu ul {
  display: flex;
  align-items: center;
  gap: 50px;
}
.header__menu li a {
  font-size: 18px;
  color: #393939;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
}
.header__menu li a:hover, .header__menu li a.active {
  color: #000;
}
.header__menu li.active > a {
  color: #000;
}
.header__menu li .icon {
  font-size: 7px;
  margin-left: 7px;
}
.header__action {
  display: flex;
  align-items: center;
}
.header__action-item {
  margin-left: 24px;
}
.header__action .btn {
  min-width: 220px;
}
.header__action-btn {
  position: relative;
  width: 50px;
  height: 50px;
  background: #393939;
  transition: background 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  border: 2px solid #393939;
}
.header__action-btn > * {
  max-width: 80%;
  max-height: 80%;
}
.header__action-btn img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-in-out;
}
.header__action-btn .img-hover {
  opacity: 0;
}
@media (any-hover: hover) {
  .header__action-btn:hover {
    background: #fff;
  }
  .header__action-btn:hover img {
    opacity: 0;
  }
  .header__action-btn:hover .img-hover {
    opacity: 1;
  }
}
.header__burger {
  display: none;
}
@media (max-width: 1023.98px) {
  .header__action {
    margin-left: auto;
  }
  .header__menu {
    display: none;
  }
  .header__burger {
    display: flex !important;
    cursor: pointer;
    position: relative;
    margin-left: 24px;
  }
  .header__burger img {
    transition: all 0.3s ease-in-out;
  }
  .header__burger .img-close {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
  }
  .header__burger.active .img-close {
    opacity: 1;
    visibility: visible;
  }
  .header__burger.active .img-open {
    opacity: 0;
    visibility: hidden;
  }
  .header__menu ul {
    flex-direction: column;
  }
  .header__menu li {
    margin: 5px 0;
  }
  .header__menu li a {
    font-size: 14px;
    padding: 6px 0;
    display: block;
    letter-spacing: 0.05em;
  }
  .header__action-item--search {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .header__container {
    max-width: 100%;
  }
  .header__wrap {
    height: 60px !important;
  }
  .header__logo {
    width: 120px;
    margin: 0 auto;
  }
  .header__logo img {
    max-height: 40px;
  }
  .header__action {
    margin: 0;
  }
  .header__action-item {
    margin: 0;
  }
  .header__action .item--btn {
    display: none;
  }
  .header__action-btn {
    width: 36px;
    height: 36px;
  }
  .header__action-btn > * {
    max-width: 16px;
    max-height: 16px;
  }
  .header__burger {
    order: -1;
    margin: 0;
    width: 36px;
    height: 36px;
  }
  .header__burger img {
    max-width: 80%;
    max-height: 80%;
  }
}

.navfix {
  display: none;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(var(--vh) * 100 - 90px);
  z-index: 99;
  text-align: center;
  padding: 50px 0 30px;
  overflow: auto;
}
.fixed .navfix {
  height: calc(var(--vh) * 100 - 90px + 20px);
}
.navfix.active {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 1219.98px) {
  .navfix {
    display: flex;
  }
}
.navfix__container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.navfix__search {
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 45px;
}
.navfix__search form {
  position: relative;
}
.navfix__search .input-search {
  height: 43px;
  line-height: 39px;
  border-radius: 100px;
  border-width: 2px;
  margin: 0;
  padding-right: 60px;
}
.navfix__search .btn-search {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
}
.navfix__contacts {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.navfix__contacts-addr {
  margin-top: 20px;
  font-size: 18px;
  color: #868686;
}
.navfix__contacts-item {
  margin-top: 0px;
  font-size: 26px;
  font-family: "Oddval", sans-serif;
  text-transform: uppercase;
}
.navfix__social {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 60px 0px 0;
}
.navfix__menu {
  margin-bottom: auto;
  flex: 1 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navfix__menu li {
  margin: 40px 0;
}
.navfix__menu li a {
  font-size: 30px;
}
.navfix__menu li .icon {
  display: none;
}
@media (max-width: 767.98px) {
  .navfix {
    height: calc(var(--vh) * 100 - 60px + 2px) !important;
  }
  .navfix__menu li {
    margin: 30px 0;
  }
  .navfix__contacts {
    margin-top: 40px;
  }
  .navfix__contacts-addr {
    font-size: 14px;
  }
  .navfix__contacts-item {
    font-size: 20px;
  }
  .navfix__social {
    margin-top: 40px;
  }
}

/**************************************************************
footer
**************************************************************/
.footer {
  padding-bottom: 25px;
}
.footer:first-child {
  padding: 200px 0;
}
.footer__inner {
  border-radius: 30px;
  border: 2px solid #E5E5E5;
  padding: 46px 57px 57px;
}
.footer__top {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}
.footer__menu {
  width: 100%;
  max-width: 235px;
}
.footer__menu ul {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 3px 15px;
}
.footer__menu li:nth-child(2n) {
  text-align: right;
}
.footer__menu li a {
  font-size: 20px;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}
.footer__menu li a:hover {
  color: #000;
}
.footer__contacts {
  text-align: right;
}
.footer__contacts-item {
  font-family: "Oddval", sans-serif;
  font-size: 75px;
  font-weight: 600;
  line-height: 0.9;
  letter-spacing: -0.045em;
  text-transform: uppercase;
  display: inline-block;
}
.footer__contacts-item + .footer__contacts-item {
  margin-top: 20px;
}
.footer__contacts-addr {
  margin-top: 20px;
  text-transform: uppercase;
  font-size: 20px;
  color: #868686;
}
.footer__bottom {
  margin-top: 90px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 40px;
  font-size: 15px;
}
.footer__copy {
  text-transform: uppercase;
}
.footer__link {
  text-transform: uppercase;
  color: #868686;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  margin-top: 13px;
}
.footer__link:hover {
  color: #393939;
}
.footer__social {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.footer__develop {
  margin-top: 20px;
}
.footer__develop a {
  letter-spacing: 0.32em;
  font-weight: 700;
  text-transform: uppercase;
}
.footer__develop a:hover {
  color: #000;
}
@media (max-width: 1439.98px) {
  .footer__contacts-item {
    font-size: 65px;
  }
}
@media (max-width: 1219.98px) {
  .footer__menu {
    max-width: 220px;
  }
  .footer__menu li a {
    font-size: 18px;
  }
  .footer__contacts-item {
    font-size: 50px;
  }
  .footer__contacts-addr {
    font-size: 18px;
  }
  .footer__bottom {
    font-size: 14px;
  }
}
@media (max-width: 1023.98px) {
  .footer__inner {
    border-radius: 20px;
    padding: 40px 30px;
  }
  .footer__top {
    flex-direction: column-reverse;
  }
  .footer__contacts {
    text-align: left;
  }
  .footer__contacts-item {
    font-size: 40px;
    display: block;
  }
  .footer__contacts-item + .footer__contacts-item {
    margin-top: 10px;
  }
  .footer__bottom {
    gap: 0;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 40px;
  }
  .footer__social {
    order: -1;
    align-items: flex-start;
  }
  .footer__inf {
    margin-top: 50px;
  }
  .footer__develo {
    margin-top: 36px;
  }
}
@media (max-width: 609.98px) {
  .footer__inner {
    padding: 40px 20px;
  }
  .footer__top {
    gap: 36px;
  }
  .footer__menu li {
    text-align: left !important;
  }
  .footer__menu li a {
    font-size: 16px;
  }
  .footer__contacts-item {
    font-size: 24px;
  }
  .footer__contacts-addr {
    font-size: 14px;
  }
  .footer__bottom {
    font-size: 12px;
    margin-top: 36px;
  }
}

/**************************************************************

**************************************************************/
.hero {
  margin-bottom: 125px;
}
.hero + * {
  margin-top: 0;
}
.hero__inner {
  margin: 0 20px;
}
.hero__slider {
  position: relative;
  padding: 0 20px;
  margin: 0 -20px;
}
.hero__slider-nav {
  position: absolute;
  left: 0;
  bottom: 50px;
  width: 100%;
  z-index: 5;
}
.hero__slider .swiper-pagination {
  margin-right: auto;
  width: auto;
}
.hero__imgbg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.hero__imgbg::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.55) 60.96%, rgba(0, 0, 0, 0.35) 79.64%);
  z-index: 1;
  content: "";
}
.hero__imgbg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  animation: zoomOutBG 20s linear forwards;
}
.hero__slider .hero__imgbg img {
  animation: initial;
}
.swiper-slide-active .hero__imgbg img {
  animation: zoomOutBG 20s linear forwards;
}
.hero__item {
  position: relative;
  max-height: 800px;
  max-height: 1000px;
  height: calc(var(--vh) * 100 - 90px - 20px) !important;
  padding: 150px 0 60px;
  border-radius: 30px;
  overflow: hidden;
  color: #fff;
}
.hero__slider .hero__item {
  padding-bottom: 150px;
}
.hero__info {
  position: relative;
  z-index: 2;
}
.hero__title {
  font-size: 100px;
  font-family: "Oddval", sans-serif;
  line-height: 0.9;
  letter-spacing: -0.045em;
  text-transform: uppercase;
  animation: fade-up 1s cubic-bezier(0.35, 0, 0, 1.01);
}
.hero__slider .hero__title {
  animation: initial;
}
.swiper-slide-active .hero__title {
  animation: fade-up 1s cubic-bezier(0.35, 0, 0, 1.01);
}
.hero__text {
  font-size: 24px;
  line-height: 1.2;
  margin-top: 24px;
  max-width: 550px;
  animation: fade-up 2s cubic-bezier(0.35, 0, 0, 1.01);
}
.hero__text.mw {
  max-width: 820px;
}
.hero__slider .hero__text {
  animation: initial;
}
.swiper-slide-active .hero__text {
  animation: fade-up 2s cubic-bezier(0.35, 0, 0, 1.01);
}
.hero__buttons {
  margin-top: 70px;
  animation: fade-up 3s cubic-bezier(0.35, 0, 0, 1.01);
}
.hero__slider .hero__buttons {
  animation: initial;
}
.swiper-slide-active .hero__buttons {
  animation: fade-up 3s cubic-bezier(0.35, 0, 0, 1.01);
}
@media (min-width: 1024px) and (max-height: 1000px) {
  .hero--lg .hero__item {
    max-height: initial;
  }
}
@media (min-width: 1024px) and (max-height: 900px) {
  .hero__item {
    max-height: initial;
    padding-top: 100px;
  }
}
@media (max-width: 1439.98px) {
  .hero__title {
    font-size: 75px;
  }
  .hero__text {
    font-size: 24px;
    max-width: 450px;
  }
  .hero__buttons {
    margin-top: 50px;
  }
}
@media (max-width: 1023.98px) {
  .hero__slider-nav {
    padding: 0 30px;
    bottom: 30px;
  }
  .hero__item {
    border-radius: 20px;
    padding: 100px 30px 0;
    max-height: 600px;
  }
  .hero__info {
    width: 100%;
  }
  .hero__title {
    font-size: 50px;
  }
  .hero__text {
    font-size: 20px;
    max-width: 400px;
  }
}
@media (max-width: 609.98px) {
  .hero {
    margin-bottom: 110px;
  }
  .hero__inner {
    margin: 0;
  }
  .hero__slider {
    padding: 0;
    margin: 0;
    position: relative;
  }
  .hero__slider-nav {
    position: relative;
    left: 0;
    bottom: 0;
    margin-top: 30px;
  }
  .hero__slider .swiper-nav {
    justify-content: center;
  }
  .hero__slider .swiper-pagination {
    justify-content: center;
    margin: 0;
    padding-bottom: 5px;
    gap: 10px;
  }
  .hero__slider .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    background: #D9D9D9;
    border-color: #D9D9D9;
  }
  .hero__slider .swiper-pagination-bullet-active {
    border-color: #393939 !important;
    background: #393939 !important;
  }
  .hero__slider .swiper-button {
    border-color: #393939;
    color: #393939;
    background: none;
    display: none;
  }
  .hero__imgbg {
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    margin: 0 16px 40px;
    height: 270px;
    width: calc(100% - 32px);
  }
  .hero__item {
    padding: 0 !important;
    display: block;
    min-height: 1px;
    max-height: initial;
    height: auto !important;
    color: #393939;
  }
  .hero__item-img {
    height: 250px;
    height: initial;
    padding-bottom: 64%;
  }
  .hero__title {
    font-size: 36px;
  }
  .hero__text {
    font-size: 18px;
    margin-top: 20px;
  }
  .hero__buttons {
    margin-top: 40px;
  }
  .hero__buttons .btn {
    background: #393939;
    color: #fff;
  }
}
@media (max-width: 478.98px) {
  .hero__title {
    font-size: 32px;
  }
  .hero__buttons .btn {
    width: 100%;
  }
}
@media (max-width: 360px) {
  .hero__title {
    font-size: 28px;
  }
}

/**************************************************************

**************************************************************/
.services {
  overflow: hidden;
}
.services__items {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0 2px 0 4px;
  padding-bottom: 2px;
}
.services__item {
  position: relative;
  padding: 26px 20px 33px;
  box-shadow: 0 0 0 2px #E5E5E5;
  background: #fff;
}
.services__item:before, .services__item:after, .services__item-lines::before, .services__item-lines::after {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 55px;
  background: #fff;
  z-index: 5;
  content: "";
}
.services__item:before {
  left: 0;
  top: 0;
}
.services__item:after {
  left: 100%;
  top: 0;
}
.services__item-lines:before {
  left: 0;
  top: 100%;
}
.services__item-lines:after {
  left: 100%;
  top: 100%;
}
.services__item-photo {
  position: relative;
  overflow: hidden;
  padding-bottom: 103%;
  border-radius: 20px;
  margin-bottom: 16px;
  visibility: visible !important;
}
.services__item-photo.wow:before {
  position: absolute;
  left: -1%;
  bottom: 0;
  width: 102%;
  height: 100%;
  z-index: 5;
  transition: all 1.2s ease-in-out;
  transition-delay: inherit;
  content: "";
  background: #fff;
}
.services__item-photo.animated:before {
  transform: translateY(100%);
}
.services__item-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
}
.services__item:hover .services__item-photo img {
  transform: scale(1.05);
}
.services__item-head {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.services__item-title {
  font-size: 25px;
  text-transform: uppercase;
}
.services__item-mlink {
  flex: 0 0 auto;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  border: 2px solid #E5E5E5;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #393939;
  transition: all 0.3s ease-in-out;
}
.services__item-mlink:hover {
  color: #fff;
  border-color: #393939;
  background: #393939;
}
.services__item-mlink .icon {
  width: 45%;
  height: 45%;
}
.services__item-price {
  margin-top: 12px;
  font-size: 16px;
  color: #868686;
}
@media (max-width: 1023.98px) {
  .services__items {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 609.98px) {
  .services__items {
    grid-template-columns: 1fr;
  }
  .services__item {
    padding: 20px;
  }
  .services__item-title {
    font-size: 24px;
    min-height: 1px;
    margin-bottom: 10px;
  }
  .services__item-price {
    margin-top: 0;
  }
}

/**************************************************************

**************************************************************/
.engaged__items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px 20px;
  overflow: hidden;
}
.engaged__item {
  position: relative;
  padding: 65px 60px 80px;
}
.engaged__item::before {
  position: absolute;
  bottom: -8px;
  left: 0;
  border-bottom: 2px solid #E5E5E5;
  width: 0;
  content: "";
  transition: all 1.3s ease-in-out;
}
.engaged__item:nth-child(2n)::before {
  left: auto;
  right: 0;
}
.engaged__item.animated:before {
  width: calc(100% - 5px);
}
.engaged__item::after {
  position: absolute;
  top: 0;
  right: -8px;
  border-right: 2px solid #E5E5E5;
  height: 0;
  content: "";
  transition: all 1.3s ease-in-out;
}
.engaged__item.animated::after {
  height: calc(100% - 5px);
}
.engaged__item-title {
  font-size: 25px;
  text-transform: uppercase;
}
.engaged__item-text {
  margin-top: 20px;
  color: #636363;
}
@media (max-width: 1219.98px) {
  .engaged__item {
    padding: 40px 30px 50px;
  }
}
@media (max-width: 1023.98px) {
  .engaged__item {
    padding: 30px 20px;
  }
  .engaged__item-title {
    font-size: 22px;
  }
}
@media (max-width: 609.98px) {
  .engaged__items {
    grid-template-columns: 1fr;
    grid-gap: 30px;
    padding: 0;
  }
  .engaged__item {
    padding: 0 0 30px;
    border-bottom: 2px solid #E5E5E5;
  }
  .engaged__item::before, .engaged__item::after {
    display: none;
  }
  .engaged__item-title {
    font-size: 20px;
  }
}

/**************************************************************

**************************************************************/
.e4steps {
  padding: 200px 0 160px;
  position: relative;
  z-index: 1;
  color: #fff;
}
.e4steps__imgbg {
  position: absolute;
  left: 16px;
  right: 16px;
  top: 0;
  height: 100%;
  border-radius: 30px;
  overflow: hidden;
  z-index: -1;
  background-position: 50%;
  background-size: cover;
  background-attachment: fixed;
}
.e4steps__imgbg::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(33, 33, 33, 0) 0%, rgba(33, 33, 33, 0.49) 100%);
  z-index: 1;
  content: "";
}
.e4steps__imgbg.v--2::before {
  background: linear-gradient(180deg, rgba(33, 33, 33, 0.55) 0%, rgba(33, 33, 33, 0.26) 100%);
}
.e4steps__imgbg.v--3::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(33, 33, 33, 0.5);
  z-index: 1;
  content: "";
}
.e4steps__imgbg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.e4steps__head {
  margin-bottom: 110px;
}
.e4steps__title {
  font-family: "Oddval", sans-serif;
  font-size: 75px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.045em;
  text-transform: uppercase;
}
.e4steps__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5px;
}
.e4steps__item {
  position: relative;
  border: 2px solid #fff;
  border-radius: 100%;
  width: 293px;
  height: 293px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.e4steps__item:hover {
  background: rgba(33, 33, 33, 0.3);
  backdrop-filter: blur(15px);
}
.e4steps__item-text {
  font-size: 25px;
  text-transform: uppercase;
  text-align: center;
  padding: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  transition: all 0.3s ease-in-out;
}
.e4steps__item-text.fs--md {
  font-size: 20px;
  text-transform: none;
}
.e4steps__item-text.text--hover {
  opacity: 0;
  visibility: hidden;
}
.item--hover:hover .e4steps__item-text {
  opacity: 0;
  visibility: hidden;
}
.item--hover:hover .e4steps__item-text.text--hover {
  opacity: 1;
  visibility: visible;
}
.e4steps__item-numb {
  font-size: 15px;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
}
.e4steps__buttons {
  margin-top: 88px;
}
@media (min-width: 1220px) and (max-height: 1000px) {
  .e4steps {
    padding: 140px 0 100px;
  }
  .e4steps__head {
    margin-bottom: 80px;
  }
}
@media (max-width: 1439.98px) {
  .e4steps__item {
    width: 280px;
    height: 280px;
  }
}
@media (max-width: 1219.98px) {
  .e4steps {
    padding: 140px 0 100px;
  }
  .e4steps__head {
    margin-bottom: 60px;
  }
  .e4steps__title {
    font-size: 65px;
  }
  .e4steps__item {
    width: 230px;
    height: 230px;
  }
  .e4steps__item-text {
    padding: 20px;
    font-size: 20px;
  }
  .e4steps__item-text.fs--md {
    font-size: 16px;
  }
  .e4steps__item-numb {
    top: 24px;
  }
  .e4steps__buttons {
    margin-top: 60px;
  }
}
@media (max-width: 1023.98px) {
  .e4steps__wrap {
    padding: 0 40px;
  }
  .e4steps__imgbg {
    border-radius: 20px;
  }
  .e4steps__title {
    font-size: 50px;
  }
  .e4steps__items {
    justify-content: center;
    gap: 24px;
  }
  .e4steps__item {
    background: rgba(33, 33, 33, 0.3);
    backdrop-filter: blur(15px);
  }
  .e4steps__buttons {
    text-align: center;
  }
}
@media (max-width: 609.98px) {
  .e4steps {
    padding: 60px 0 24px;
  }
  .e4steps__imgbg {
    background-attachment: scroll;
  }
  .e4steps__wrap {
    padding: 0 30px;
    padding: 0 20px;
  }
  .e4steps__head {
    margin-bottom: 40px;
  }
  .e4steps__title {
    font-size: 32px;
    font-size: 28px;
  }
  .e4steps__buttons {
    margin-top: 48px;
  }
  .e4steps__buttons .btn {
    width: 100%;
    max-width: 400px;
  }
}

/**************************************************************

**************************************************************/
.advant__item {
  display: flex;
  gap: 30px;
  padding-bottom: 55px;
  border-bottom: 2px solid #E5E5E5;
}
.advant__item + .advant__item {
  margin-top: 55px;
}
.advant__item-numb {
  flex: 0 0 auto;
  width: 23%;
  font-size: 30px;
}
.advant__item-title {
  flex: 0 0 auto;
  width: 270px;
  font-size: 24px;
  line-height: 1.18;
  text-transform: uppercase;
}
.advant__item-text {
  flex: 1 0 0;
  color: #636363;
}
.advant__row {
  padding-bottom: 50px;
  padding-right: 80px;
  border-bottom: 2px solid #E5E5E5;
  position: relative;
}
.advant__row + .advant__row {
  margin-top: 50px;
}
.advant__row-inner {
  display: flex;
  gap: 30px;
  cursor: pointer;
}
.advant__row-head {
  width: 50%;
  flex: 0 0 auto;
  min-height: 50px;
  cursor: pointer;
}
.advant__row-title {
  max-width: 470px;
  line-height: 1.18;
  font-size: 24px;
  text-transform: uppercase;
}
.advant__row-plus {
  position: absolute;
  right: 0;
  top: 0;
  flex: 0 0 auto;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  border: 2px solid #E5E5E5;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #393939;
  transition: all 0.3s ease-in-out;
  transform: rotate(0deg);
}
.advant__row-plus .icon {
  width: 45%;
  height: 45%;
}
.advant__row-inner:hover .advant__row-plus {
  color: #fff;
  border-color: #393939;
  background: #393939;
}
.advant__row.opened .advant__row-plus {
  transform: rotate(90deg);
}
.advant__row-body {
  flex: 1 0 0;
  max-width: 470px;
  display: none;
  color: #636363;
}
@media (max-width: 1219.98px) {
  .advant__row {
    padding-bottom: 30px;
  }
  .advant__row + .advant__row {
    margin-top: 30px;
  }
}
@media (max-width: 1023.98px) {
  .advant__item {
    padding-bottom: 40px;
    flex-wrap: wrap;
  }
  .advant__item + .advant__item {
    margin-top: 40px;
  }
  .advant__item-numb {
    width: 100px;
  }
  .advant__item-title {
    flex: 1 0 0;
    width: auto;
  }
  .advant__item-text {
    width: 100%;
    flex: 0 0 auto;
  }
  .advant__row {
    padding-right: 0;
  }
  .advant__row-inner {
    display: block;
  }
  .advant__row-head {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
  .advant__row-plus {
    position: static;
  }
  .advant__row-title {
    font-size: 22px;
    max-width: 100%;
  }
  .advant__row-body {
    padding-top: 20px;
  }
}
@media (max-width: 609.98px) {
  .advant__item {
    padding-bottom: 30px;
    gap: 10px;
  }
  .advant__item + .advant__item {
    margin-top: 30px;
  }
  .advant__item-numb {
    width: 50px;
    font-size: 24px;
  }
  .advant__item-title {
    font-size: 20px;
  }
  .advant__row-head {
    gap: 50px;
  }
  .advant__row-title {
    font-size: 20px;
  }
  .advant__row-plus {
    width: 46px;
    height: 46px;
  }
}

/**************************************************************

**************************************************************/
.mabout {
  padding-bottom: 130px;
  text-align: center;
  position: relative;
}
.mabout__photo {
  position: absolute;
  border-radius: 10px;
  overflow: hidden;
  z-index: -1;
}
.mabout__photo.photo--1 {
  left: 0;
  top: 0;
  width: 153px;
  height: 153px;
}
.mabout__photo.photo--2 {
  left: 70px;
  bottom: -17%;
  width: 168px;
  height: 223px;
  transform: translateY(27%);
}
.mabout__photo.photo--3 {
  right: 30px;
  bottom: 27px;
  width: 263px;
  height: 174px;
}
.mabout__photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.mabout__info {
  position: relative;
  z-index: 1;
  padding-top: 130px;
}
.mabout__title {
  font-family: "Oddval", sans-serif;
  font-size: 80px;
  text-transform: uppercase;
  line-height: 0.9;
}
.mabout__buttons {
  margin-top: 50px;
}
@media (max-width: 1439.98px) {
  .mabout__title {
    font-size: 70px;
  }
}
@media (max-width: 1023.98px) {
  .mabout {
    padding-bottom: 80px;
  }
  .mabout__info {
    padding-top: 80px;
  }
  .mabout__title {
    font-size: 50px;
  }
  .mabout__photo.photo--1 {
    left: 10px;
    width: 90px;
    height: 90px;
  }
  .mabout__photo.photo--2 {
    left: 50px;
    width: 110px;
    height: 150px;
    transform: translateY(20%);
  }
  .mabout__photo.photo--3 {
    right: 0px;
    bottom: 10px;
    width: 200px;
    height: 130px;
  }
}
@media (max-width: 609.98px) {
  .mabout {
    padding: 0;
  }
  .mabout__info {
    padding: 0;
  }
  .mabout__title {
    font-size: 28px;
  }
  .mabout__photo.photo--1 {
    display: none;
  }
  .mabout__photo.photo--2 {
    display: none;
  }
  .mabout__photo.photo--3 {
    position: relative;
    right: 0px;
    bottom: 0;
    width: 100%;
    height: auto;
    padding-bottom: 60%;
    border-radius: 20px;
    margin-top: 24px;
    transform: initial !important;
  }
  .mabout__buttons {
    margin-top: 24px;
  }
}
@media (max-width: 360px) {
  .mabout__title {
    font-size: 24px;
  }
}

/**************************************************************

**************************************************************/
.blog {
  overflow: hidden;
}
.blog__items {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0 2px 6px;
}
.blog__item {
  position: relative;
  padding: 24px 20px 32px;
  box-shadow: 0 0 0 2px #E5E5E5;
  margin: 0 0 -2px -2px;
  background: #fff;
}
.blog__item:before, .blog__item:after, .blog__item-lines::before, .blog__item-lines::after {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 55px;
  background: #fff;
  z-index: 5;
  content: "";
}
.blog__item:before {
  left: 0;
  top: 0;
}
.blog__item:after {
  left: 100%;
  top: 0;
}
.blog__item-lines:before {
  left: 0;
  top: 100%;
}
.blog__item-lines:after {
  left: 100%;
  top: 100%;
}
.blog__item-photo {
  position: relative;
  z-index: 10;
  overflow: hidden;
  padding-bottom: 45%;
  border-radius: 20px;
  margin-bottom: 16px;
  visibility: visible !important;
}
.blog__item-photo.wow:before {
  position: absolute;
  left: -1%;
  bottom: 0;
  width: 102%;
  height: 100%;
  z-index: 5;
  transition: all 1.2s ease-in-out;
  transition-delay: inherit;
  content: "";
  background: #fff;
}
.blog__item-photo.animated:before {
  transform: translateY(100%);
}
.blog__item-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
}
.blog__item:hover .blog__item-photo img {
  transform: scale(1.05);
}
.blog__item-head {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.blog__item-title {
  font-size: 24px;
  text-transform: uppercase;
  min-height: 55px;
}
.blog__item-mlink {
  flex: 0 0 auto;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  border: 2px solid #E5E5E5;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #393939;
  transition: all 0.3s ease-in-out;
}
.blog__item-mlink:hover {
  color: #fff;
  border-color: #393939;
  background: #393939;
}
.blog__item-mlink .icon {
  width: 45%;
  height: 45%;
}
.blog__item-props {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}
.blog__item-prop {
  font-size: 14px;
  text-transform: uppercase;
}
@media (max-width: 1023.98px) {
  .blog__item {
    padding: 20px 14px 30px;
  }
  .blog__item:before, .blog__item:after, .blog__item-lines::before, .blog__item-lines::after {
    width: 30px;
    height: 40px;
    margin-top: -3px;
  }
  .blog__item-photo {
    padding-bottom: 55%;
  }
  .blog__item-title {
    font-size: 20px;
    min-height: 1px;
  }
  .blog__item-mlink {
    width: 44px;
    height: 44px;
  }
  .blog__item-prop {
    font-size: 12px;
  }
}
@media (max-width: 609.98px) {
  .blog__items {
    grid-template-columns: 1fr;
  }
}

/**************************************************************

**************************************************************/
.blogd__head {
  margin-bottom: 42px;
  padding-bottom: 42px;
  border-bottom: 2px solid #E5E5E5;
}
.blogd__title {
  letter-spacing: -0.045em;
}
.blogd__props {
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 30px;
}
.blogd__prop {
  font-size: 15px;
  text-transform: uppercase;
}
.blogd__intro {
  margin-top: 42px;
  font-size: 30px;
}
.blogd__photo {
  margin-top: 64px;
  padding-bottom: 60%;
  position: relative;
  overflow: hidden;
}
.blogd__photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.blogd__content {
  margin-top: 82px;
}
@media (min-width: 1024px) and (max-height: 800px) {
  .blogd__photo {
    padding-bottom: 52%;
  }
}
@media (max-width: 1219.98px) {
  .blogd__head {
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
  .blogd__props {
    margin-top: 24px;
  }
  .blogd__intro {
    font-size: 24px;
    margin-top: 30px;
  }
  .blogd__photo {
    margin-top: 50px;
  }
}
@media (max-width: 609.98px) {
  .blogd__head {
    padding-bottom: 20px;
    margin-bottom: 24px;
  }
  .blogd__prop {
    font-size: 14px;
  }
  .blogd__intro {
    font-size: 20px;
    margin-top: 24px;
  }
  .blogd__photo {
    margin-top: 40px;
  }
}

/**************************************************************

**************************************************************/
.ordform {
  position: relative;
  z-index: 1;
  color: #fff;
}
.ordform__imgbg {
  position: absolute;
  left: 16px;
  right: 16px;
  top: 0;
  height: 100%;
  border-radius: 30px;
  overflow: hidden;
  z-index: -1;
  background-position: 50%;
  background-size: cover;
  background-attachment: fixed;
}
.ordform__imgbg::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(33, 33, 33, 0.71) 0%, rgba(33, 33, 33, 0.49) 100%);
  z-index: 1;
  content: "";
}
.ordform__imgbg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.ordform__inner {
  padding: 137px 172px;
  border-radius: 30px;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(15px);
}
.ordform__head {
  margin-bottom: 30px;
}
.ordform__title {
  font-family: "Oddval", sans-serif;
  font-size: 75px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.045em;
  text-transform: uppercase;
}
.ordform__subtitle {
  margin-top: 20px;
  font-size: 25px;
  text-transform: uppercase;
}
.ordform__form {
  width: 85%;
  max-width: 720px;
}
@media (max-width: 1439.98px) {
  .ordform__inner {
    padding: 100px 130px;
  }
  .ordform__title {
    font-size: 70px;
  }
  .ordform__subtitle {
    font-size: 20px;
  }
}
@media (max-width: 1219.98px) {
  .ordform__title {
    font-size: 55px;
  }
}
@media (max-width: 1023.98px) {
  .ordform__imgbg {
    border-radius: 20px;
  }
  .ordform__inner {
    padding: 50px 30px;
    margin: 0 20px;
    border-radius: 20px;
  }
  .ordform__title {
    font-size: 36px;
  }
  .ordform__subtitle {
    font-size: 18px;
  }
}
@media (max-width: 767.98px) {
  .ordform {
    padding: 0;
  }
  .ordform__inner {
    margin: 0;
    padding: 80px 20px;
  }
  .ordform__head {
    text-align: center;
    margin-bottom: 50px;
  }
  .ordform__title {
    font-size: 28px;
  }
  .ordform__subtitle {
    font-size: 20px;
    margin-top: 50px;
  }
  .ordform__form {
    width: 100%;
  }
}

/**************************************************************

**************************************************************/
.blocks + .blocks {
  margin-top: 150px;
}
.blocks__inner {
  display: flex;
  justify-content: space-between;
}
.blocks__image {
  flex: 0 0 auto;
  width: 50%;
  max-width: 600px;
  overflow: hidden;
}
.blocks__image-wrap {
  padding-bottom: 80%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
}
.blocks__image-wrap.wow:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 102%;
  height: 100%;
  z-index: 5;
  transition: all 1.2s ease-in-out;
  transition-delay: inherit;
  content: "";
  background: #fff;
}
.blocks__image-wrap.animated:before {
  transform: translateX(-100%);
}
.blocks__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.blocks--reverse .blocks__image {
  order: -1;
  padding-right: 80px;
}
.blocks--reverse .blocks__image-wrap.wow::before {
  left: auto;
  right: 0;
}
.blocks--reverse .blocks__image-wrap.animated:before {
  transform: translateX(100%);
}
.blocks__info {
  flex: 1 0 0;
  padding: 64px 115px 64px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.blocks--reverse .blocks__info {
  max-width: 100%;
}
.blocks__box + .blocks__box {
  margin-top: 105px;
}
.blocks__title {
  font-size: 24px;
  text-transform: uppercase;
}
.blocks__text {
  margin-top: 20px;
  color: #636363;
}
.blocks__buttons {
  margin-top: 50px;
}
@media (max-width: 1219.98px) {
  .blocks + .blocks {
    margin-top: 100px;
  }
  .blocks__info {
    padding: 40px 80px 40px 0;
  }
  .blocks__box + .blocks__box {
    margin-top: 70px;
  }
  .blocks__title {
    font-size: 22px;
  }
}
@media (max-width: 1023.98px) {
  .blocks + .blocks {
    margin-top: 40px;
  }
  .blocks__inner {
    flex-direction: column !important;
  }
  .blocks__image {
    order: 1 !important;
    margin-top: 30px;
    padding: 0 !important;
    width: 100%;
  }
  .blocks__image-wrap {
    padding-bottom: 70%;
  }
  .blocks__info {
    padding: 0;
  }
  .blocks__box {
    border-bottom: 2px solid #E5E5E5;
    padding-bottom: 30px;
  }
  .blocks__box + .blocks__box {
    margin-top: 30px;
  }
}
@media (max-width: 609.98px) {
  .blocks__title {
    font-size: 20px;
  }
}

/**************************************************************

**************************************************************/
.works__head {
  margin-bottom: 54px;
}
.works__intro {
  max-width: 920px;
  margin-bottom: 36px;
}
.works__carousel {
  overflow: hidden;
  border-radius: 20px;
}
.works__item {
  height: 420px;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  width: calc(var(--w) * 0.3625 - 10px);
}
.works__item:nth-child(2n) {
  width: calc(var(--w) * 0.6375);
}
.works__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
@media (max-width: 1219.98px) {
  .works__item {
    height: 300px;
  }
}
@media (max-width: 1023.98px) {
  .works__head {
    margin-bottom: 30px;
  }
  .works__carousel-nav {
    justify-content: center;
  }
}
@media (max-width: 609.98px) {
  .works__head {
    margin-bottom: 20px;
  }
  .works__item {
    height: 230px;
  }
}

/**************************************************************

**************************************************************/
.faq__items {
  border-top: 2px solid #E5E5E5;
}
.faq__item {
  padding: 26px 0;
  border-bottom: 2px solid #E5E5E5;
  position: relative;
}
.faq__item-head {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  cursor: pointer;
}
.faq__item-title {
  line-height: 1.18;
  font-size: 24px;
  text-transform: uppercase;
  min-height: 50px;
  display: flex;
  align-items: center;
}
.faq__item-plus {
  flex: 0 0 auto;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  border: 2px solid #E5E5E5;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #393939;
  transition: all 0.3s ease-in-out;
  transform: rotate(0deg);
}
.faq__item-plus .icon {
  width: 45%;
  height: 45%;
}
.faq__item-head:hover .faq__item-plus {
  color: #fff;
  border-color: #393939;
  background: #393939;
}
.faq__item.opened .faq__item-plus {
  transform: rotate(90deg);
}
.faq__item-body {
  display: none;
  padding-right: 190px;
  padding-top: 10px;
  color: #636363;
}
@media (max-width: 1023.98px) {
  .faq__item-title {
    font-size: 22px;
  }
  .faq__item-body {
    padding-top: 20px;
    padding-right: 100px;
  }
}
@media (max-width: 609.98px) {
  .faq__item-title {
    font-size: 20px;
    min-height: 46px;
  }
  .faq__item-plus {
    width: 46px;
    height: 46px;
  }
  .faq__item-body {
    padding-right: 0;
  }
}

/**************************************************************

**************************************************************/
.reviews__slider {
  padding-top: 40px;
  border-top: 2px solid #E5E5E5;
}
.reviews__item {
  display: flex;
  gap: 60px;
}
.reviews__item-photo {
  flex: 0 0 auto;
  width: 240px;
  height: 240px;
  border-radius: 100%;
  position: relative;
  overflow: hidden;
}
.reviews__item-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.reviews__item-info {
  max-width: 580px;
}
.reviews__item-name {
  font-size: 24px;
  font-weight: 600;
}
.reviews__item-text {
  font-size: 15px;
  margin-top: 25px;
}
@media (max-width: 1023.98px) {
  .reviews__slider-nav {
    justify-content: center;
  }
  .reviews__item {
    gap: 30px;
  }
  .reviews__item-photo {
    width: 180px;
    height: 180px;
  }
  .reviews__item-name {
    font-size: 22px;
  }
  .reviews__item-text {
    margin-top: 20px;
  }
}
@media (max-width: 609.98px) {
  .reviews__slider {
    padding: 0;
    border: none;
  }
  .reviews__item {
    flex-direction: column;
    gap: 24px;
  }
  .reviews__item-photo {
    width: 120px;
    height: 120px;
    margin: 0 auto;
  }
  .reviews__item-name {
    font-size: 20px;
  }
  .reviews__item-text {
    font-size: 14px;
  }
}

/**************************************************************
.error404
**************************************************************/
.error404 {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.error404__numb {
  font-size: 96px;
  line-height: 1;
  text-transform: uppercase;
}
.error404__title {
  font-size: 45px;
  text-transform: uppercase;
}
.error404__buttons {
  padding-top: 60px;
}
@media (max-width: 1023.98px) {
  .error404__numb {
    font-size: 76px;
  }
  .error404__title {
    font-size: 32px;
  }
  .error404__buttons {
    padding-top: 30px;
  }
}
@media (max-width: 609.98px) {
  .error404__numb {
    font-size: 64px;
  }
  .error404__title {
    font-size: 26px;
  }
  .error404__buttons {
    padding-top: 30px;
  }
}

/**************************************************************

**************************************************************/
.team__items {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 60px;
}
.team__item-photo {
  position: relative;
  padding-bottom: 100%;
  margin-bottom: 24px;
}
.team__item-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.team__item-info {
  font-size: 15px;
}
.team__item-name {
  color: #868686;
}
.team__item-work {
  margin-top: 5px;
}
@media (max-width: 1219.98px) {
  .team__items {
    grid-gap: 30px;
  }
}
@media (max-width: 1023.98px) {
  .team__items {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 60px;
  }
}
@media (max-width: 767.98px) {
  .team__items {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
  }
}
@media (max-width: 609.98px) {
  .team__items {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 478.98px) {
  .team__items {
    grid-template-columns: 1fr;
    grid-gap: 24px;
  }
  .team__item {
    display: flex;
    align-items: center;
  }
  .team__item-photo {
    flex: 0 0 auto;
    margin: 0 16px 0 0;
    width: 110px;
    height: 110px;
    padding: 0;
  }
  .team__item-info {
    font-size: 20px;
  }
}
@media (max-width: 360px) {
  .team__item-photo {
    width: 100px;
    height: 100px;
  }
  .team__item-info {
    font-size: 16px;
  }
}

/**************************************************************

**************************************************************/
.why__inner {
  display: flex;
  flex-direction: column;
}
.why__items {
  position: relative;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.why__items + .why__items {
  margin-top: 40px;
}
.why__items:first-child {
  order: -2;
}
.why__items:not(:last-child) {
  padding-bottom: 30px;
  border-bottom: 2px solid #E5E5E5;
}
.why__items:not(:last-child)::before {
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -50%);
  width: 15px;
  height: 15px;
  background: #393939;
  border-radius: 100px;
  z-index: 1;
  content: "";
}
.why__item {
  flex: 0 0 auto;
  width: calc(50% - 5px);
}
.why__item-numb {
  font-size: 15px;
  margin-bottom: 20px;
}
.why__photo {
  order: -1;
  margin: 56px 0 16px;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  padding-bottom: 36%;
}
.why__photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
@media (max-width: 1023.98px) {
  .why__photo {
    border-radius: 20px;
    padding-bottom: 50%;
  }
  .why__items {
    display: block;
    padding: 0 !important;
    border: none !important;
  }
  .why__items + .why__items {
    margin-top: 30px;
  }
  .why__items::before {
    display: none !important;
  }
  .why__item {
    width: 100%;
    position: relative;
    padding-bottom: 30px;
    border-bottom: 2px solid #E5E5E5;
  }
  .why__item + .why__item {
    margin-top: 30px;
  }
  .why__item::before {
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, -50%);
    width: 15px;
    height: 15px;
    background: #393939;
    border-radius: 100px;
    z-index: 1;
    content: "";
  }
}
@media (max-width: 609.98px) {
  .why__photo {
    order: 5;
    margin: 30px 0 0;
    padding-bottom: 65%;
  }
}

/**************************************************************

**************************************************************/
.ahero {
  position: relative;
  color: #fff;
}
.ahero + * {
  margin-top: 120px;
}
.ahero__imgbg {
  position: absolute;
  top: 0;
  left: 20px;
  right: 20px;
  height: 100%;
  border-radius: 30px;
  overflow: hidden;
}
.ahero__imgbg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.ahero__inner {
  position: relative;
  z-index: 2;
  min-height: calc(var(--vh) * 100 - 90px - 20px);
  max-height: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 130px 120px 70px;
}
.ahero__title {
  font-family: "Oddval", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 21vw;
  letter-spacing: -0.045em;
  line-height: 1;
  position: relative;
  animation: fade-up 2s cubic-bezier(0.35, 0, 0, 1.01);
}
.ahero__title .st {
  font-family: "Jost", sans-serif;
  font-weight: 500;
  font-size: 5.5vw;
  letter-spacing: 0.38em;
  position: absolute;
  left: 0;
  top: 100%;
  transform: translate(4%, -20%);
}
.ahero__imgt {
  display: flex;
  animation: fade-up 2s cubic-bezier(0.35, 0, 0, 1.01);
}
.ahero__imgt img {
  width: 100%;
}
.ahero__intro {
  display: flex;
  justify-content: flex-end;
  font-size: 30px;
  text-transform: uppercase;
  position: relative;
  top: -85px;
  animation: fade-up 3s cubic-bezier(0.35, 0, 0, 1.01);
}
.ahero__intro-inner {
  max-width: 610px;
}
@media (max-width: 1699.98px) {
  .ahero__title {
    font-size: 19.7vw;
  }
  .ahero__title .st {
    font-size: 5vw;
  }
  .ahero__intro {
    font-size: 24px;
    top: -60px;
  }
}
@media (max-width: 1439.98px) {
  .ahero__inner {
    padding-left: 70px;
    padding-right: 70px;
  }
  .ahero__intro {
    font-size: 20px;
    top: -50px;
  }
}
@media (max-width: 1023.98px) {
  .ahero__imgbg {
    border-radius: 20px;
  }
  .ahero__inner {
    padding-left: 50px;
    padding-right: 50px;
  }
  .ahero__title .st {
    position: static;
  }
  .ahero__intro {
    display: block;
    margin-top: 60px;
    transform: initial;
    top: 0;
  }
  .ahero__intro-inner {
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .ahero__imgbg {
    left: 16px;
    right: 16px;
  }
}
@media (max-width: 609.98px) {
  .ahero + * {
    margin-top: 75px;
  }
  .ahero__inner {
    height: 450px;
    min-height: 1px;
    padding: 100px 35px 60px;
  }
  .ahero__intro {
    font-size: 18px;
  }
}

/**************************************************************

**************************************************************/
/**************************************************************

**************************************************************/