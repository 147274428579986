
.navfix {
    display: none;
    opacity: 0; visibility: hidden;
    transition: all 0.3s ease-in-out;
    background: #fff;
    position: fixed;
    bottom: 0; left: 0;
    width: 100%;
    height: calc(var(--vh) * 100 - $header-h);
    z-index: 99;
    text-align: center;
    padding: 50px 0 30px;
    overflow: auto;
    .fixed & {
        height: calc(var(--vh) * 100 - $header-h + 20px);
    }
    &.active {
        opacity: 1; visibility: visible;
    }

    @media (max-width: $md-desktop) {
        display: flex;
    }

    &__container {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    &__search {
        max-width: 600px;
        margin: 0 auto;
        margin-bottom: 45px;
        form {
            position: relative;
        }
        .input-search {
            height: 43px;
            line-height: 39px;
            border-radius: 100px;
            border-width: 2px;
            margin: 0;
            padding-right: 60px;
        }
        .btn-search {
            position: absolute;
            width: 40px; height: 40px;
            right: 10px; top: 50%;
            transform: translateY(-50%);
            border: none;
            background: none;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            outline: none;
        }
    }

    &__contacts {
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &-addr {
           margin-top: 20px;
           font-size: 18px;
           color: $color-gray;
        }
        &-item {
            margin-top: 0px;
            font-size: 26px;
            font-family: $font-oddval;
            text-transform: uppercase;
        }
    }
    &__social {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 60px 0px 0;
    }

    &__menu {
        margin-bottom: auto;
        flex: 1 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        li {
            margin: 40px 0;
            a {
                font-size: 30px;
            }
            .icon {
                display: none;
            }
        }
    }

    @media (max-width: $md-tablet-sm) {
        height: calc(var(--vh) * 100 - $header-h-m + 2px) !important;
        
        &__menu {
            li {
                margin: 30px 0;
            }
        }
        &__contacts {
            margin-top: 40px;
            &-addr {
                font-size: 14px;
            }
            &-item {
                font-size: 20px;
            }
        }
        &__social {
            margin-top: 40px;
        }
    }
}