.icon {
    stroke: currentColor;
    fill: none;
    color: inherit;
    font-size: inherit;
    transition: all 0.3s ease-in-out;
    flex: 0 0 auto;
    &--fill {
        fill: currentColor;
        stroke: initial;
    }
    path {
        stroke: currentColor;
        color: inherit;
        font-size: inherit;
        transition: all 0.3s ease-in-out;
    }
}

.social {
    &__items {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    &__item {
        width: 135px; height: 135px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid $color-gray-light;
        border-radius: 100px;
        position: relative;
        transition: all 0.3s ease-in-out;
        z-index: 1;
        &:hover {
            background: $color-gray-light;
        }
        &::after {
            @include abs-center;
            width: 48%; height: 48%;
            background: $color-body;
            border-radius: 100%;
            z-index: -1;
            content: '';
        }
        img {
            width: 27px; 
        }
    }
    .icon {
        width: 19px; height: 19px;
    }

    @media (max-width: $md-desktop) {
        &__items {
            gap: 10px;
        }
        &__item {
            width: 90px; height: 90px;
            &::after {
                width: 57%;
                height: 57%;
            }
            img {
                width: 20px;
            }
        }
    }
    @media (max-width: $md-tablet-sm) {
        
        &__items {
            gap: 19px;
        }
        &__item {
            width: 70px; height: 70px;
            img {
                width: 15px;
            }
        }
    }
}   





.link {

    font-size: 20px;
    line-height: lh(27, 20);
    font-weight: 700;
    transition: all 0.3s ease-in-out;
    display: inline-flex;
    align-items: center;
    .icon {
        width: 20px; height: 20px;
        margin-left: 8px;
    }
    &:hover {
       
    }
    &__more {
        .close_t {
            display:none
        }
        &.opened .open_t {
            display:none
        }
        &.opened .close_t {
            display:block
        }
    }
    @media (max-width: $md-desktop) {
        font-size: 16px
    }
}

// .content__toggle {
//     > * {
//         display: none;
//     }
//     & > :nth-child(1),
//     & > :nth-child(2) {
//         display: block;
//     }
//     &.opened > * {
//         display: block !important;
//     }
//     &--mobile {
//         > * {
//             display: block;
//         }
//     }
//     @media (max-width: $md-tablet-sm) {
//         > * {
//             display: none !important;
//         }
//         & > :nth-child(1) {
//             display: block !important;
//         }
//     }
// }

/**************************************************************
.navs
**************************************************************/
.navs {
    margin-bottom: 70px;
    padding-bottom: 30px;
    border-bottom: 2px solid $color-gray-light;
    &__list {
        ul {
            display: flex;
            align-items: center;
        }
        li {
            margin-right: 30px;
            display: flex;
            align-items: center;
            &:first-child {
                &:after {
                    width: 6px; height: 6px;
                    background: $color-gray-light;
                    border-radius: 100px;
                    margin-left: 30px;
                    content: '';
                }
            }
            a {
                color: $color-body;
                text-transform: uppercase;
                font-size: 16px;
                transition: all 0.3s ease-in-out;
                &:hover {
                    color: $color-body-hover;
                }
                &.active {
                    font-weight: 600;
                }
            }
            &.current a {
                font-weight: 600;
            }
        }
    }
    @media (max-width: $md-tablet-sm) {
        
    }
}

/**************************************************************
pagination
**************************************************************/
.pagination {
    margin-top: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    .page-item {
        margin: 0 12px;
    }
    .page-link {
        border-radius: 100px;
        border: 2px solid $color-gray-light;
        background: none;
        width: 50px; height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease-in-out;
        font-size: 20px;
        color: $color-gray;
        font-weight: 600;
        &:hover {
            color: #fff !important;
            background: $color-body;
            border-color: $color-body;
        }
        .icon {
            width: 32%;
        }
    }
    .page-item.active .page-link {
        color: $color-body !important;
        pointer-events: none;
    }
    .page-item.disabled {
        display: none;
    }
    .page-item.disabled .page-link {
        cursor: default;
        background: none;
       
    }
    .page-next .page-link, .page-prev .page-link {
        color: $color-body;
    }
    .page-next .icon {
        transform: translateX(2px);
    }
    .page-prev .icon {
        transform: translateX(-1px);
    }
    .page-last, .page-first {
        display: none;
    }

    @media (max-width: $md-tablet) {
        margin-top: 40px;
        .page-item {
            margin: 0 5px;
        }
        .page-link {
            background: none;
            font-size: 14px;
            width: 40px; height: 40px;
        }
    }
    @media (max-width: $md-mobile-lg) {
        & {
            margin-top: 32px;
        }
        .page-item {
            display: none;
        }
        .page-prev, .page-next, .page-item.active, .page-item.active + .page-item, .page-item.active + .page-item + .page-item {
            display: block;
        }
    }
}

